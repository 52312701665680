.content-blog-home {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 30px;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: flex-start
}

.blog-post-home {
    text-align: center;
    width: 400px;
}

.post-content-home {
    width: 400px;
    position: relative;
}

.img-container-home-blog {
    position: relative;
    width: 100%;
    height: 200px;
}

.img-container-home-blog img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.category-overlay {
    position: absolute;
    bottom: 0;
    width: 95%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.blog-post-home h2 {
    text-align: left;
    font-size: 15px;
    color: #495057;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.blog-post-home p {
    text-align: left;
    font-size: 14px;
    color: #495057;
}

.button-and-date-home-blog-post {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* Add margin-top to separate the button and date from the heading */
}

.blog-post-home a {
    display: block;
    width: 25%;
    text-align: center;
    font-size: 12px;
    background-color: #454545;
    color: white;
    text-decoration: none;
    font-weight: 600;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.blog-post-home a:hover {
    background-color: #fdb819;
    color: white;
    text-decoration: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.date-post-blog-home {
    display: flex;
    align-items: center;
}

.blog-post-home p {
    text-align: justify;
    font-size: 12px;
    color: #495057;
    margin-left: 5px;
}

.clock-home-blog {
    font-size: 12px;
    color: #495057;
}

.pagination-blog {
    display: flex;
    justify-content: center;
    /* Centre horizontalement */
    align-items: center;
    /* Aligne verticalement */
    margin-top: 20px;
}

.span-pagination-blog {
    position: relative;
    z-index: 2;
    display: inline-block;
    transition: color 0.3s ease;
}

.prev-page-blog,
.next-page-blog {
    position: relative;
    display: inline-block;
    width: 200px;
    padding: 5px 10px;
    /* Augmente la taille */
    font-size: 17px;
    /* Agrandit le texte */
    font-weight: 600;
    text-transform: uppercase;
    background-color: white;
    color: black;
    border: 3px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.prev-page-blog::before,
.next-page-blog::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    color: white;
    background-color: #FDB819;
    z-index: 1;
    transition: all 0.6s ease;
    transform: translateX(-50%);
}

.prev-page-blog:hover,
.next-page-blog:hover {
    border-color: #FDB819;
    color: white;
    /* Change la couleur du texte au survol */
}

.prev-page-blog:hover::before,
.next-page-blog:hover::before {
    width: 100%;
    /* L'effet de fermeture des portes */
}

.page-indicator-blog {
    font-size: 20px;
    font-weight: 600;
    margin: 0 10px;
}

.blog-current-page {
    font-size: 24px;
    font-weight: 800;
    color: #fdb819;
}

.blog-page-link {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 600;
}

@media screen and (min-width: 890px) and (max-width: 1210px) {
    .content-blog-home {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-container-blog-left {
        margin-right: 0;
    }
}

@media screen and (min-width: 700px) and (max-width: 890px) {
    .blog-post-home {
        text-align: center;
        width: 650px;
    }

    .post-content-home {
        width: 650px;
        position: relative;
    }

    .content-blog-home {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-container-blog-left {
        margin-right: 0;
    }

    .category-overlay {
        width: 630px;
    }

    .img-container-home-blog {
        height: auto;
    }

    .img-container-home-blog img {
        width: 650px;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (min-width: 400px) and (max-width: 866px) {
    .container-wrapper-blog {
        margin-top: 130px;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
    .blog-post-home {
        text-align: center;
        width: 550px;
    }

    .post-content-home {
        width: 550px;
        position: relative;
    }

    .content-blog-home {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-container-blog-left {
        margin-right: 0;
    }

    .category-overlay {
        width: 530px;
    }

    .img-container-home-blog {
        height: auto;
    }

    .img-container-home-blog img {
        width: 550px;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
    .blog-post-home {
        text-align: center;
        width: 450px;
    }

    .post-content-home {
        width: 450px;
        position: relative;
    }

    .content-blog-home {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-container-blog-left {
        margin-right: 0;
    }

    .category-overlay {
        width: 430px;
    }

    .img-container-home-blog {
        height: auto;
    }

    .img-container-home-blog img {
        width: 450px;
        height: 100%;
        object-fit: cover;
    }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
    .prev-page-blog,
    .next-page-blog {
        width: 150px;
        padding: 3px 8px;
        font-size: 14px;
    }

    .page-indicator-blog {
        font-size: 18px;
        margin: 0 10px;
    }

    .blog-current-page {
        font-size: 20px;
    }

    .blog-page-link {
        font-size: 16px;
    }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
    .blog-post-home {
        text-align: center;
        width: 350px;
    }

    .post-content-home {
        width: 350px;
        position: relative;
    }

    .content-blog-home {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-container-blog-left {
        margin-right: 0;
    }

    .category-overlay {
        width: 330px;
    }

    .img-container-home-blog {
        height: auto;
    }

    .img-container-home-blog img {
        width: 350px;
        height: 100%;
        object-fit: cover;
    }

    .prev-page-blog,
    .next-page-blog {
        width: 100px;
        padding: 3px 6px;
        font-size: 13px;
    }

    .page-indicator-blog {
        font-size: 16px;
        margin: 0 10px;
    }

    .blog-current-page {
        font-size: 18px;
    }

    .blog-page-link {
        font-size: 14px;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
.container-wrapper-blog {
    margin-top: 110px;
}
}

@media screen and (min-width: 0px) and (max-width: 290px) {
    .container-wrapper-blog {
        margin-top: 80px;
    }
    }