.container-wrapper-productshop {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    /* Assurez-vous que la hauteur de la navbar est compensée */
}

.menu-shop-container-burger {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.menu-shop-burger {
    display: none;
    margin-bottom: 30px;
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #607576;
    cursor: pointer;
    padding: 5px 20px 5px 20px;
    text-transform: uppercase;
    background-color: white;
    border: 3px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: color 0.3s ease, border-color 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.menu-shop-burger::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: #FDB819;
    z-index: 0;
    transition: width 0.6s ease-in-out, left 0.6s ease-in-out;
}

.menu-shop-burger:hover::before {
    width: 100%;
    left: 0;
}

.menu-shop-burger:hover {
    border-color: #FDB819;
    color: white;
}

.menu-shop-burger span {
    position: relative;
    z-index: 1;
    transition: color 0.3s ease;
}

.menu-shop-burger:hover span {
    color: white;
}

.main-container-productshop {
    display: flex;
    width: 100%;
    max-width: 1180px;
}

/* .total-productshop occupe la largeur totale du conteneur parent */
.total-productshop {
    width: 100%;
    /* Prend toute la largeur disponible */
    display: flex;
    flex-direction: column;
    /* Permet d'empiler les éléments verticalement */
    margin-top: 60px;
    margin-left: 30px;
}

/* Les produits affichés s'organisent en ligne avec flex-wrap */
.productshop-element {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Espacement entre les éléments */
}

.pagination {
    display: flex;
    justify-content: center;
    /* Centre horizontalement */
    align-items: center;
    /* Aligne verticalement */
    margin-top: 20px;
}

.span-pagination-shop {
    position: relative;
    z-index: 2;
    display: inline-block;
    transition: color 0.3s ease;
}

.prev-page,
.next-page {
    position: relative;
    display: inline-block;
    width: 200px;
    padding: 5px 10px;
    /* Augmente la taille */
    font-size: 17px;
    /* Agrandit le texte */
    font-weight: 600;
    text-transform: uppercase;
    background-color: white;
    color: black;
    border: 3px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.prev-page::before,
.next-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    color: white;
    background-color: #FDB819;
    z-index: 1;
    transition: all 0.6s ease;
    transform: translateX(-50%);
}

.prev-page:hover,
.next-page:hover {
    border-color: #FDB819;
    color: white;
    /* Change la couleur du texte au survol */
}

.prev-page:hover::before,
.next-page:hover::before {
    width: 100%;
    /* L'effet de fermeture des portes */
}

.page-indicator {
    font-size: 20px;
    font-weight: 600;
    margin: 0 10px;
}

.current-page {
    font-size: 24px;
    font-weight: 800;
    color: #fdb819;
}

.page-link-shop {
    text-decoration: none;
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.productshop-element-list {
    text-align: center;
    position: relative;
    /* Added for icon positioning */
}

.productshop-element-list:hover {
    cursor: pointer;
    border: 1px solid #FDB819;
}

.img-productshop {
    width: 230px;
    height: 230px;
}

.productshop-name {
    font-size: 14px;
    color: #607576;
}

.productshop-price {
    font-size: 14px;
    font-weight: 600;
    color: #fdb819;
}

.productshop-name,
.productshop-price {
    width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 5px 0;
    padding-left: 20px;
    padding-right: 20px;
}

.original-price-product-shop {
    text-decoration: line-through;
    color: #607576;
    font-size: 15px;
    margin-right: 10px;
}

.discounted-price-product-shop {
    color: #fdb819;
    font-weight: bold;
    font-size: 14px;
}

.display-productshop-weight {
    margin-top: -1px;
    font-size: 13px;
    color: #607576;
}

.img-container {
    position: relative;
}

.icon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* S'assurer que le conteneur prend toute la largeur */
}

.icon-overlay i {
    font-size: 18px;
    color: #fdb819;
    background-color: #607576;
    border-radius: 50%;
    padding: 10px;
    transition: opacity 0.3s;
}

.icon-overlay i:hover {
    font-size: 18px;
    color: #607576;
    background-color: #fdb819;
    border-radius: 50%;
    padding: 10px;
}

/* S'assurer que l'icône est toujours centrée, même s'il y en a qu'une seule */
.icon-overlay.single-icon {
    justify-content: center;
    /* Centre le contenu quand il n'y a qu'une icône */
}

/* Rendre visible les icônes sur hover */
.productshop-element-list:hover .icon-overlay {
    opacity: 1;
}

.productshop-element-list:hover .img-overlay {
    opacity: 1;
}

@media screen and (min-width: 0px) and (max-width: 1300px) {
    .container-wrapper-productshop {
        margin-top: 110px;
        /* Assurez-vous que la hauteur de la navbar est compensée */
    }

    /* .total-productshop occupe la largeur totale du conteneur parent */
    .total-productshop {
        margin-left: 0px;
    }

    /* Les produits affichés s'organisent en ligne avec flex-wrap */
    .productshop-element {
        justify-content: center;
        /* Centre les éléments horizontalement */
    }

    .menu-shop-burger {
        display: block;
        margin-right: 20px;
    }
}

@media screen and (min-width: 400px) and (max-width: 1050px) {

    .prev-page,
    .next-page {
        width: 150px;
        padding: 3px 7px;
        /* Augmente la taille */
        font-size: 14px;
        /* Agrandit le texte */
        font-weight: 600;
    }

    .page-indicator {
        font-size: 14px;
        font-weight: 600;
        margin: 0 10px;
    }

    .current-page {
        font-size: 17px;
        font-weight: 800;
        color: #fdb819;
    }

    .page-link-shop {
        text-decoration: none;
        color: black;
        font-size: 15px;
        font-weight: 600;
    }
}

@media screen and (min-width: 400px) and (max-width: 866px) {
    .menu-shop-burger {
        margin-top: -30px;
    }
}

@media screen and (min-width: 0px) and (max-width: 400px) {

    .prev-page,
    .next-page {
        width: 100px;
        padding: 2px 5px;
        /* Augmente la taille */
        font-size: 12px;
        /* Agrandit le texte */
        font-weight: 600;
    }

    .page-indicator {
        font-size: 12px;
        font-weight: 600;
        margin: 0 10px;
    }

    .current-page {
        font-size: 15px;
        font-weight: 800;
        color: #fdb819;
    }

    .page-link-shop {
        text-decoration: none;
        color: black;
        font-size: 14px;
        font-weight: 600;
    }

    .menu-shop-burger {
        margin-top: -50px;
        margin-bottom: 10px;
    }
}