.total-shop-products-subcategory-display {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.shop-products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.shop-products-display-list {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1150px;
    gap: 30px;
    margin-bottom: 20px;
}

.img-shop-product-display {
    width: 200px;
    height: 200px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.shop-product-display {
    margin: 10px;
    text-align: center;
    position: relative;
}

.shop-product-display:hover {
    border: solid 1px;
    border-color: #fdb819;
    cursor: pointer;
    transform: scale(1.05);
}

.img-container {
    position: relative; /* Ajouté pour le positionnement des icônes */
}

.img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    opacity: 0;
    transition: opacity 0.3s;
}

.icon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s;
}

.shop-product-display:hover .img-overlay,
.shop-product-display:hover .icon-overlay {
    opacity: 1;
}

.product-shop-display-name {
    font-size: 14px;
    color: #607576;
}

.product-shop-display-price {
    font-size: 14px;
    font-weight: 600;
    color: #fdb819;
}

.product-shop-display-name,
.product-shop-display-price {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 5px 0;
    padding-left: 20px;
    padding-right: 20px;
}

.original-price-product-shop-display {
    text-decoration: line-through;
    color: #607576;
    font-size: 15px;
    margin-right: 10px;
}

.discounted-price-product-shop-display {
    color: #fdb819;
    font-weight: bold;
    font-size: 14px;
}

.shop-product-display-weight {
    font-size: 13px;
    color: #607576;
    margin-top: -2px;
    margin-bottom: -3px;
}

.product-chevron-left,
.product-chevron-right {
    cursor: pointer;
    font-size: 24px;
    color: #607576;
    background-color: #EEEEEE;
    border-radius: 50%;
    padding: 5px;
    transition: color 0.3s;
}

.product-chevron-left:hover,
.product-chevron-right:hover {
    color: white;
    background-color: #fdb819;
}

.button-product-shop-product-display {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #fdb819;
    border: none;
    border-radius: 50px;
    padding: 15px 32px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-product-shop-product-display:hover {
    background-color: #ddd;
}

/* Media queries */
@media screen and (min-width: 1020px) and (max-width: 1280px) {
    .shop-products-display-list {
        width: 900px;
    }
}

@media screen and (min-width: 950px) and (max-width: 1020px) {
    .shop-products-display-list {
        width: 850px;
    }
}

@media screen and (min-width:850px) and (max-width: 950px) {
    .shop-products-display-list {
        width: 750px;
    }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
    .shop-products-display-list {
        width: 650px;
    }
}

@media screen and (max-width: 750px) {
    .shop-products-display-list .shop-product-display {
        display: none;
    }

    .shop-products-display-list .shop-product-display:nth-child(-n+4) {
        display: block;
    }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
    .shop-products-display-list {
        width: 550px;
    }
}

@media screen and (min-width: 570px) and (max-width: 651px) {
    .shop-products-display-list .shop-product-display {
        display: none;
    }

    .shop-products-display-list .shop-product-display:nth-child(-n+2) {
        display: block;
    }
}

@media screen and (min-width: 540px) and (max-width: 650px) {
    .shop-products-display-list {
        width: 450px;
    }
}

@media screen and (max-width: 570px) {
    .shop-products-display-list .shop-product-display {
        display: none;
    }

    .shop-products-display-list .shop-product-display:nth-child(-n+1) {
        display: block;
    }
}

@media screen and (min-width: 0px) and (max-width: 570px) {
    .button-product-shop-product-display {
        padding: 10px 22px;
        font-size: 14px;
    }
}

@media screen and (min-width: 450px) and (max-width: 540px) {
    .shop-products-display-list {
        width: 350px;
    }
}

@media screen and (min-width: 370px) and (max-width: 450px) {
    .shop-products-display-list {
        width: 270px;
    }
}

@media screen and (min-width: 345px) and (max-width: 370px) {
    .shop-products-display-list {
        width: 260px;
    }

    .img-shop-product-display {
        width: 130px;
        height: 130px;
    }

    .product-chevron-left,
    .product-chevron-right {
        font-size: 18px;
    }
}

@media screen and (min-width: 320px) and (max-width: 345px) {
    .shop-products-display-list {
        width: 240px;
    }

    .img-shop-product-display {
        width: 130px;
        height: 130px;
    }

    .product-chevron-left,
    .product-chevron-right {
        font-size: 18px;
    }

    .product-shop-display-name,
    .product-shop-display-price {
        width: 130px;
    }
}

@media screen and (min-width: 270px) and (max-width: 320px) {
    .shop-products-display-list {
        width: 200px;
    }

    .img-shop-product-display {
        width: 100px;
        height: 100px;
    }

    .product-chevron-left,
    .product-chevron-right {
        font-size: 18px;
    }

    .product-shop-display-name,
    .product-shop-display-price {
        width: 100px;
    }
}

@media screen and (min-width: 230px) and (max-width: 270px) {
    .shop-products-display-list {
        width: 150px;
    }

    .img-shop-product-display {
        width: 80px;
        height: 80px;
    }

    .product-chevron-left,
    .product-chevron-right {
        font-size: 18px;
    }

    .product-shop-display-name,
    .product-shop-display-price {
        width: 80px;
    }

    .button-product-shop-product-display {
        padding: 8px 18px;
        font-size: 12px;
    }
}

@media screen and (min-width: 200px) and (max-width: 230px) {
    .shop-products-display-list {
        width: 140px;
    }

    .img-shop-product-display {
        width: 75px;
        height: 75px;
    }

    .product-chevron-left,
    .product-chevron-right {
        font-size: 16px;
    }

    .product-shop-display-name,
    .product-shop-display-price {
        width: 75px;
        font-size: 12px;
    }

    .button-product-shop-product-display {
        padding: 6px 16px;
        font-size: 10px;
    }
}

@media screen and (min-width: 0px) and (max-width: 200px) {
    .shop-products-display-list {
        width: 120px;
    }

    .img-shop-product-display {
        width: 65px;
        height: 65px;
    }

    .product-chevron-left,
    .product-chevron-right {
        font-size: 14px;
    }

    .product-shop-display-name,
    .product-shop-display-price {
        width: 65px;
        font-size: 10px;
    }

    .button-product-shop-product-display {
        padding: 5px 14px;
        font-size: 8px;
    }
}