/* Footer top*/
/* ________________________________________________________________________ */

.footer-top {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    gap: 50px;
}

.footer-container {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: white;
    width: 350px;
    transform: translateY(50%);
    z-index: 2;
    border: 1px solid #e0e0e0;
}

.footer-icon-container {
    text-align: center;
    align-items: center;
    background-color: #E3C121;
    width: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
    border-bottom: 2px solid #e0e0e0;
}

.assurance1 {
    margin-top: -10px;
    text-align: center;
    font-size: 12px;
    padding: 5px;
}

.assurance2 {
    text-align: center;
    font-size: 12px;
    padding: 5px;
    color: #666;
}

.footer-top2 {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
}

.footer-container2 {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: white;
    width: 80%;
    transform: translateY(50%);
    z-index: 2;
    border: 1px solid #e0e0e0;
}

.footer-icon-container2 {
    text-align: center;
    align-items: center;
    background-color: #E3C121;
    width: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 50%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
    border-bottom: 2px solid #e0e0e0;
}

.footer-container3 {
    display: flex;
    gap: -90px;
}

.assurance3 {
    margin-top: -10px;
    text-align: center;
    font-size: 12px;
    padding: 5px;
}

.assurance4 {
    text-align: center;
    font-size: 12px;
    padding: 5px;
    color: #666;
}

.caroussel-assurance {
    display: none;
}

.caroussel-assurance.active {
    display: block;
}

/* Footer bottom*/
/* ________________________________________________________________________ */

.footer-bottom {
    position: relative;
    height: auto;
    overflow: hidden;
    z-index: 1;
    background-color: black; /* Couleur de fond de secours */
}

.footer-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.footer-background-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Assure que l'image couvre toute la section sans se déformer */
    filter: brightness(0.6); /* Ajoute un effet de sombre si nécessaire */
    display: block;
    z-index: 1;
}

.footer-bottom::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
}

.total-menu-footer {
    justify-content: center;
    top: 100px;
    margin-bottom: 150px;
    display: flex;
    position: relative;
    z-index: 3;
    gap: 260px;
    padding-left: 25px;
    padding-right: 25px;
}

.menu-footer {
    display: flex;
    flex-direction: column; /* Aligne les catégories verticalement */
}

.menu-footer-title {
    margin: 0; /* Retirer les marges verticales inutiles */
    padding: 10px 0; /* Ajouter un espace vertical cohérent */
    font-size: 18px; /* Taille du texte */
    color: white;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: 8px; /* Espace entre le texte et le chevron */
}

.menu-footer-title:hover {
    color: #FDB819; /* Changer de couleur au survol */
}

.menu-footer-subcategories {
    display: none; /* Masque par défaut */
    flex-direction: column; /* Aligne les sous-catégories verticalement */
}

.title-category-footer {
    font-size: 18px; /* Même taille que le titre */
    color: white; /* Même couleur que le titre */
    transition: transform 0.3s ease; /* Animation pour effet au hover */
    cursor: pointer; /* Curseur en main pour indiquer l'interaction */
}

.title-category-footer:hover {
    color: #FDB819; /* Changer de couleur au survol */
}

.title-filter-footer {
    font-size: 18px; /* Même taille que les titres */
    color: white; /* Même couleur que les titres */
    transition: transform 0.3s ease; /* Animation pour effet au hover */
    cursor: pointer; /* Curseur en main pour indiquer l'interaction */
}

.title-filter-footer:hover {
    color: #FDB819; /* Changer de couleur au survol */
    transform: rotate(180deg); /* Effet de rotation */
}

.menu-footer-subfilters {
    display: none; /* Masque par défaut */
    flex-direction: column; /* Aligne les sous-filtres verticalement */
}

.menu-footer-title-submenu1 {

    font-size: 18px;
    color: white;
}

.menu-footer-title-submenu2 {

    font-size: 18px;
    color: white;
}

.menu-footer-title-submenu3 {
    font-size: 18px;
    color: white;
}

.menu-footer a {
    text-decoration: none;
    color: white;
}

.menu-footer a:hover {
    color: #FDB819;
    cursor: pointer;
}

.menu-footer-subfilters a {
    text-decoration: none;
    color: white;
}

.menu-footer-subfilters a:hover {
    color: #FDB819;
}

.menu-footer-subfilters,
.menu-footer-p a {
    text-decoration: none;
    color: white;
}

.menu-footer-subfilters,
.menu-footer-p a:hover {
    color: #FDB819;
}

.menu-footer-p {
    margin-bottom: 5px; /* Réduit l'espace entre les éléments */
    margin-top: 0; /* Assurez-vous qu'il n'y ait pas d'espace inutile au-dessus */
    font-size: 13px;
    color: #ddd;
    line-height: 1.5; /* Ajuste l'espacement vertical entre les lignes pour plus de cohérence */
}


/* Footer newsletter*/
/* ________________________________________________________________________ */

.newsletter {
    display: flex;
    align-items: center;
    background-color: black;
    opacity: 0.8;
    height: 130px;
    position: relative;
    z-index: 3;
}

.title-newsletter {
    margin-left: 500px;
    font-size: 13px;
    color: #ddd;
    margin-right: 30px;
}

.input-group {
    width: 350px;
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 50px;
    background-color: #1c1c1c;
}

.input-group:hover {
    background-color: #FDB819;
    border: none;
}

.input-newsletter {
    flex-grow: 1;
    border: none;
    padding: 10px;
    border-radius: 50px 0px 0px 50px;
    outline: none;
    color: #454545;
    background-color: #1c1c1c;
}

.send-icon {
    color: #707070;
    margin-right: 15px;
    cursor: pointer;
}

.bi-send-fill {
    font-size: 15px;
    margin-left: 15px;
    color: #ddd;
}

/* Footer copyright*/
/* ________________________________________________________________________ */

.footer-copyright {
    text-align: center;
}

.copyright {
    position: relative;
    color: white;
    font-size: 13px;
    z-index: 3;
}

.highlight {
    color: #E3C121;
}

/* Reponsiv footer-top */
/* ________________________________________________________________________ */

@media screen and (min-width: 735px) and (max-width: 1185px) {
    .footer-top {
        margin-left: 20px;
        margin-right: 20px;
        gap: 50px;
    }
}

@media screen and (min-width: 635px) and (max-width: 755px) {
    .footer-top {
        gap: 30px;
    }
}

@media screen and (min-width: 776px) {
    .footer-top2 {
        display: none;
    }
}

@media screen and (max-width: 775px) {
    .footer-top {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 190px) {
    .assurance3 {
        font-size: 8px;
        padding: 5px;
    }

    .assurance4 {
        margin-top: -10px;
        font-size: 7px;
        font-weight: 700;
        padding: 5px;
    }

    .footer-icon-container2 {
        width: 20px;
        height: 20px;
        font-size: 8px;
        padding: 5px;
    }
}

    /* Reponsiv footer-bottom */
/* ________________________________________________________________________ */

@media screen and (min-width: 1035px) and (max-width: 1150px) {

    .total-menu-footer {
        gap: 200px;
    }
}

@media screen and (min-width: 935px) and (max-width: 1035px) {

    .total-menu-footer {
        gap: 150px;
    }
}

@media screen and (min-width: 835px) and (max-width: 935px) {

    .total-menu-footer {
        gap: 100px;
    }
}

@media screen and (min-width: 730px) and (max-width: 835px) {

    .total-menu-footer {
        gap: 50px;
    }
}

@media screen and (min-width: 675px) and (max-width: 730px) {

    .total-menu-footer {
        gap: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 675px) {

    .total-menu-footer {
        flex-direction: column;
        gap: 20px;
    }
}


    /* Reponsiv NewsLetter */
/* ________________________________________________________________________ */

@media screen and (min-width: 850px) and (max-width: 1150px) {
    .title-newsletter {
        margin-left: 200px;
    }
}

@media screen and (min-width: 700px) and (max-width: 850px) {
    .title-newsletter {
        margin-left: 50px;
    }
}

@media screen and (min-width: 400px) and (max-width: 700px) {
    .newsletter {
        display: flex;
        flex-direction: column;
    }

    .title-newsletter {
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media screen and (min-width: 340px) and (max-width: 400px) {
    .newsletter {
        display: flex;
        flex-direction: column;
    }

    .title-newsletter {
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .input-group {
        width: 300px;
    }
}

@media screen and (min-width: 0px) and (max-width: 340px) {
    .newsletter {
        display: flex;
        flex-direction: column;
    }

    .title-newsletter {
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .input-group {
        width: 250px;
    }
}