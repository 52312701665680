.total-blog-display-home {
    margin-top: 20px;
    display: flex;
}

.blog-post-display-home {
    position: relative;
    display: inline-block;
    width: 100%;
}

.blog-post-display-home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 6px;
    background-color: rgba(0, 0, 0, 0.6); /* Fond noir semi-transparent */
    z-index: 1;
}

.blog-post-display-home::before:hover {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 6px;
    background-color: rgb(255, 255, 255); /* Fond noir semi-transparent */
    z-index: 1;
}

.img-blog-diplay-home {
    width: 100%;
    height: 400px;
    object-fit: cover;
    z-index: 1;
}

.blog-post-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white; /* Couleur du texte */
    text-align: center;
    transform: translateY(-130%);
    z-index: 2;
}

.date-blog-display-home,
.title-blog-display-home,
.read-article {
    margin: 0; /* Réinitialise les marges par défaut */
}

.date-blog-display-home {
    font-size: 20px; /* Taille de la date */
    font-weight: 700;
}

.title-blog-display-home {
    font-size: 32px; /* Taille du titre */
    font-weight: 700;
}

.read-article {
    font-size: 20px; /* Taille du titre */
    font-weight: 700;
    cursor: pointer;
    color: #FDB819;
    transition: background-color 0.3s;
}

.read-article a {
    color: inherit;
    text-decoration: none;

}

.read-article:hover {
    color: white;
}

@media screen and (min-width: 950px) and (max-width: 1150px) {
    .date-blog-display-home {
        font-size: 18px;
    }
    
    .title-blog-display-home {
        font-size: 28px;
    }
    
    .read-article {
        font-size: 18px;
    }

    .blog-post-details {
        transform: translateY(-140%);
    }
}

@media screen and (min-width: 790px) and (max-width: 950px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }

    .blog-post-details {
        transform: translateY(-140%);
    }
}

@media screen and (min-width: 580px) and (max-width: 790px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }
}

@media screen and (min-width: 430px) and (max-width: 580px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .date-blog-display-home {
        font-size: 15px;
    }
    
    .title-blog-display-home {
        font-size: 22px;
    }
    
    .read-article {
        font-size: 15px;
    }

    .blog-post-details {
        transform: translateY(-190%);
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }
}

@media screen and (min-width: 290px) and (max-width: 430px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .date-blog-display-home {
        font-size: 18px;
    }
    
    .title-blog-display-home {
        font-size: 23px;
    }
    
    .read-article {
        font-size: 16px;
    }

    .blog-post-details {
        transform: translateY(-140%);
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }

    .img-blog-diplay-home {
        width: 100%;
        height: 300px;
    }
}

@media screen and (min-width: 270px) and (max-width: 290px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .date-blog-display-home {
        font-size: 12px;
    }
    
    .title-blog-display-home {
        font-size: 16px;
    }
    
    .read-article {
        font-size: 12px;
    }

    .blog-post-details {
        transform: translateY(-270%);
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }
}

@media screen and (min-width: 220px) and (max-width: 270px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .date-blog-display-home {
        font-size: 10px;
    }
    
    .title-blog-display-home {
        font-size: 14px;
    }
    
    .read-article {
        font-size: 10px;
    }

    .blog-post-details {
        transform: translateY(-320%);
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }
}

@media screen and (min-width: 180px) and (max-width: 220px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .img-blog-diplay-home {
        height: 300px;
    }

    .date-blog-display-home {
        font-size: 8px;
    }
    
    .title-blog-display-home {
        font-size: 12px;
    }
    
    .read-article {
        font-size: 8px;
    }

    .blog-post-details {
        transform: translateY(-300%);
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }
}

@media screen and (min-width: 0px) and (max-width: 180px) {
    .total-blog-display-home {
        flex-direction: column;
        margin-top: 0px;
    }

    .img-blog-diplay-home {
        height: 250px;
    }

    .date-blog-display-home {
        font-size: 7px;
    }
    
    .title-blog-display-home {
        font-size: 10px;
    }
    
    .read-article {
        font-size: 7px;
    }

    .blog-post-details {
        transform: translateY(-300%);
    }

    .blog-post-display-home {
        margin-bottom: -7px;
    }
}