.total-cart {
    display: flex; /* Utilise Flexbox pour faciliter le centrage */
    flex-direction: column; /* Arrange les éléments verticalement */
    align-items: center; /* Centre horizontalement */
    justify-content: center; /* Centre verticalement */
    text-align: center;
    width: 80%; /* Ajuste la largeur pour mieux remplir l'écran */
    margin: 0 auto; /* Centre le conteneur sur la page */
}

.title-cart {
    width: 100%; /* Aligne le titre à gauche tout en prenant toute la largeur */
    font-size: 1.5em;
    margin-top: 180px; /* Assurez-vous que la hauteur de la navbar est compensée */
    margin-bottom: 20px;
}

table {
    width: 100%; /* Table occupe toute la largeur disponible */
    border-collapse: collapse; /* Fusionne les bordures pour un aspect plus net */
}

thead th {
    text-align: left; /* Aligne les titres de colonnes à gauche */
    padding: 10px; /* Ajoute un padding pour l'espacement */
    background-color: #f7f7f7; /* Couleur de fond pour l'en-tête */
    border-bottom: 2px solid #ddd; /* Bordure en bas de l'en-tête */
}

tbody td {
    padding: 10px; /* Espacement autour du contenu des cellules */
    border-bottom: 1px solid #ddd; /* Bordure en bas des lignes */
    vertical-align: middle; /* Centre verticalement le contenu */
}

.img-and-product-info {
    display: flex;
    align-items: center; /* Aligne l'image et le texte au centre verticalement */
}

.product-info {
    display: flex;
    flex-direction: column; /* Arrange le nom du produit et le lien Supprimer en colonne */
    align-items: flex-start; /* Aligne le texte à gauche */
}

.product-name {
    margin-bottom: 5px; /* Ajoute un petit espace entre le nom du produit et le lien Supprimer */
}

.product-image-cart {
    width: 70px; /* Taille de l'image du produit */
    height: auto; /* Conserve le ratio de l'image */
    margin-right: 10px; /* Espace entre l'image et le nom du produit */
    border: 1px solid #ddd; /* Optionnel : ajoute une bordure autour de l'image */
    border-radius: 4px; /* Optionnel : arrondit les coins de l'image */
}

.remove-link {
    margin-top:10px;
    font-size: 0.9em; /* Taille du texte légèrement plus petite pour le lien Supprimer */
    color: #e74c3c; /* Couleur rouge pour le lien Supprimer */
    text-decoration: none; /* Supprime la décoration de lien */
}

.remove-link:hover {
    text-decoration: underline; /* Ajoute un soulignement au survol */
}

.quantity-input {
    margin-top: 10px; /* Ajoute un espace en haut du champ de quantité */
    width: 60px; /* Largeur pour le champ de quantité */
    text-align: center; /* Centre le texte dans le champ de quantité */
}
/* Prix original barré lorsque promo active */
.original-price-cart {
    color: #999; /* Couleur pour le prix barré */
    text-decoration: line-through;
}

/* Prix HT normal sans promotion */
.original-price-cart.no-promo {
    color: #000; /* Couleur noire pour les prix sans promotion */
    text-decoration: none; /* Pas de barre pour les prix sans promotion */
}

/* Prix remisé */
.discounted-price-cart {
    color: #fdb819; /* Couleur pour le prix remisé */
    font-weight: bold; /* Mettre en gras le prix remisé */
}

a.btn.btn-primary {
    display: inline-block; /* Affiche le bouton comme un bloc en ligne */
    padding: 10px 20px; /* Espacement interne du bouton */
    background-color: #333; /* Couleur de fond pour le bouton de paiement */
    color: #fff; /* Couleur du texte du bouton */
    text-decoration: none; /* Supprime la décoration de lien */
    border-radius: 4px; /* Arrondit les coins du bouton */
    font-weight: bold; /* Met le texte en gras */
}

a.btn.btn-primary:hover {
    background-color: #444; /* Change la couleur de fond au survol */
}

#total-price-ht-cart {
    font-size: 1.4em; /* Augmente la taille du texte pour le total */
    font-weight: bold; /* Met en gras le prix total */
    color: #333; /* Couleur du texte */
}

#total-price-cart {
    font-size: 1.4em; /* Augmente la taille du texte pour le total */
    font-weight: bold; /* Met en gras le prix total */
    color: #333; /* Couleur du texte */
}

.total-result-cart {
    text-align: right; /* Aligne le total à droite */
    width: 100%; /* Prend toute la largeur disponible */
    margin-top: 20px; /* Ajoute un espacement en haut */
}

.total-cart-container {
    text-align: right; /* Aligne le total à droite */
    width: 100%; /* Prend toute la largeur disponible */
    margin-top: 20px; /* Ajoute un espacement en haut */
}

.button-group-cart {
    display: flex;
    justify-content: flex-end; /* Aligne les boutons à droite */
    gap: 10px; /* Espacement entre les boutons */
    margin-top: 20px; /* Ajoute un espace en haut des boutons */
}

.btn {
    display: inline-block; /* Affiche le bouton comme un bloc en ligne */
    padding: 10px 20px; /* Espacement interne du bouton */
    border-radius: 4px; /* Arrondit les coins du bouton */
    font-weight: bold; /* Met le texte en gras */
    text-align: center; /* Centre le texte dans le bouton */
    text-decoration: none; /* Supprime la décoration de lien */
    cursor: pointer; /* Change le curseur au survol */
}

.btn-primary {
    background-color: #333; /* Couleur de fond pour le bouton de mise à jour */
    color: #fff; /* Couleur du texte du bouton */
}

.btn-primary:hover {
    background-color: #444; /* Change la couleur de fond au survol */
}

.btn-secondary {
    background-color: #fdb819; /* Couleur de fond pour le bouton de vidage */
    color: #fff; /* Couleur du texte du bouton */
}

.btn-secondary:hover {
    background-color: #ddd; /* Change la couleur de fond au survol */
}
.payment-button-container {
    display: flex;
    justify-content: flex-end; /* Aligne le bouton à droite */
    width: 100%; /* Prend toute la largeur disponible */
    margin-top: 20px; /* Ajoute un espace au-dessus du bouton */
}

.payment-button-container .btn-primary {
    background-color: #28a745; /* Couleur différente pour le bouton "Payer" (facultatif) */
}

.payment-button-container .btn-primary:hover {
    background-color: #218838; /* Couleur de fond au survol */
}


