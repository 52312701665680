.latest-blog-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.blog-post {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-and-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.image-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.image-container img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.date-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #FDB819;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 3px;
    width: 70px;
}

.date-overlay .day {
    display: block;
    font-size: 1.2em;
    font-weight: bold;
}

.date-overlay .month {
    display: block;
    font-size: 0.8em;
}

.content-container {
    background-color: #f9f9f9;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.content-container .title-blog-post,
.content-container .text-blog-post {
    margin: 0;
    text-align: justify;
}

.content-container .title-blog-post {
    margin-bottom: 10px;
}

.content-container .blog-post-content {
    margin-top: 10px;
    text-align: justify;
}

.read-more {
    margin-top: 10px;
}

.read-more a {
    color: #FDB819;
    text-decoration: none;
    font-weight: 700;
}

@media screen and (min-width: 1160px) and (max-width: 1300px) {
    .blog-post {
        width: 350px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1160px) {
    .blog-post {
        width: 300px;
    }
}

@media screen and (min-width: 860px) and (max-width: 1000px) {
    .blog-post {
        width: 250px;
    }

    .image-container img {
        height: 300px;
    }
}

@media screen and (min-width: 511px) and (max-width: 860px) {
    .blog-post {
        width: 220px;
    }

    .image-container img {
        height: 300px;
    }
}

@media screen and (min-width: 290px) and (max-width: 450px) {
    .date-overlay .day {
        font-size: 1.1em;
        font-weight: 600;
    }

    .date-overlay .month {
        font-size: 0.8em;
    }


    .image-container img {
        height: 300px;
    }

    .title-blog-post {
        font-size: 17px;
    }

    .blog-post-content p.text-blog-post {
        font-size: 14px;
    }
}

@media screen and (min-width: 220px) and (max-width: 290px) {
    .date-overlay .day {
        font-size: 0.8em;
        font-weight: 600;
    }

    .date-overlay .month {
        font-size: 0.6em;
    }

    .image-container img {
        height: 200px;
    }

    .title-blog-post {
        font-size: 15px;
    }

    .blog-post-content {
        font-size: 10px;
    }
}

@media screen and (min-width: 0px) and (max-width: 220px) {
    .date-overlay .day {
        font-size: 0.6em;
        font-weight: 500;
    }

    .date-overlay .month {
        font-size: 0.5em;
    }

    .image-container img {
        height: 150px;
    }

    .title-blog-post {
        font-size: 11px;
    }

    .text-blog-post {
        font-size: 8px;
    }

    .blog-post-content {
        font-size: 8px;
    }

    .read-more a {
        font-size: 10px;
    }
}

