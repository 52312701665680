.email-validation-container {
    text-align: center;
}

.email-validation-success,
.email-validation-danger {
    margin-top: 150px; /* Assurez-vous que la hauteur de la navbar est compensée */
    color: white;
    padding: 80px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.email-validation-success {
    background: linear-gradient(135deg, #454545, #fdb819);
}

.email-validation-danger {
    background: linear-gradient(135deg, #454545, #ff4f4f);
}

.email-validation-success:before,
.email-validation-danger:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    animation: move 4s infinite linear;
    z-index: -1;
}

@keyframes move {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.email-validation-success p,
.email-validation-danger p {
    margin: 0;
    padding: 0;
}

.email-validation-success a,
.email-validation-danger a {
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
    transition: color 0.3s ease;
}

.email-validation-success a:hover,
.email-validation-danger a:hover {
    color: #fdfdfd;
}
