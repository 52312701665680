.form-group-quote {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    transition: opacity 3s ease, visibility 0.3s ease, height 0.5s ease, margin-top 0.5s ease;
    opacity: 1;
    visibility: visible;
    height: auto;
}

.hidden-quote-section {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
}

.form-quote-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.form-quote {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
}

.form-quote h1 {
    margin-top: 150px; /* Assurez-vous que la hauteur de la navbar est compensée */
    text-align: center;
    margin-bottom: 30px;
}

.form-request-type-container {
    display: flex;
    gap : 30px;
}

.form-quote-left-and-right {
    display: flex;
    justify-content: space-between;
}

.form-quote-left,
.form-quote-right {
    width: 49%;
}

/* Styles spécifiques à form-quote-request */
.form-quote-request .form-label-quote {
    margin-bottom: 5px;
    font-weight: 600;
}

.form-quote-request label.required {
    margin-right: 30px;
}


.form-label-quote-condition {
    font-size: 15px;
    font-weight: 400;
}

.form-check-input-quote {
    margin-top: 15px;
    margin-bottom: 20px;
}

.form-control-quote {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.btn-quote {
    width: 100%;
    background-color: #454545;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.btn-quote:hover {
    background-color: #fdb819;
    color: white;
}

/* Responsive adjustments */

/* Pour les écrans moyens (tablettes) */
@media (max-width: 866px) {
    .form-quote h1 {
        margin-top: 130px; /* Ajustez si nécessaire */
    }
}

/* Pour les écrans moyens (tablettes) */
@media (max-width: 768px) {
    .form-quote-container {
        max-width: 90%;
        padding: 15px;
    }

    .form-quote h1 {
        font-size: 24px;
        margin-top: 120px; /* Ajustez si nécessaire */
    }

    .form-quote-left-and-right {
        flex-direction: column;
        gap: 20px;
    }

    .form-quote-left,
    .form-quote-right {
        width: 100%;
    }

    .form-request-type-container {
        flex-direction: column;
        gap: 15px;
    }

    .btn-quote {
        padding: 10px 15px;
        font-size: 14px;
    }
}

/* Pour les petits écrans (smartphones) */
@media (max-width: 480px) {

    .form-quote h1 {
        font-size: 20px;
        margin-top: 120px;
    }

    .form-control-quote {
        padding: 8px;
        font-size: 14px;
    }

    .btn-quote {
        padding: 8px 10px;
        font-size: 12px;
    }
}

/* Pour les très petits écrans */
@media (max-width: 400px) {
    .form-quote h1 {
        margin-top: 100px;
    }
}

/* Pour les très petits écrans */
@media (max-width: 290px) {
    .form-quote h1 {
        font-size: 18px;
        margin-top: 80px;
    }

    .form-control-quote {
        padding: 6px;
        font-size: 12px;
    }

    .btn-quote {
        padding: 6px 10px;
        font-size: 10px;
    }
}
