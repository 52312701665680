.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.reset-password-form-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.reset-password-container h1 {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 30px;
}

.reset-password-email {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Styles du bouton */
.btn-reset-password {
    width: 100%;
    background-color: #454545;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 15px;
}

.btn-reset-password:hover {
    background-color: #fdb819;
    color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .reset-password-container {
        max-width: 90%;
        padding: 15px;
    }

    .reset-password-container h1 {
        font-size: 24px;
    }

    .btn-reset-password {
        padding: 10px 15px;
        font-size: 14px;
    }
}

/* Pour les petits écrans */
@media (max-width: 480px) {
    .reset-password-container h1 {
        text-align: left;
        font-size: 20px;
    }

    .reset-password-email {
        padding: 8px;
        font-size: 14px;
    }

    .btn-reset-password {
        padding: 8px 10px;
        font-size: 12px;
    }
}
