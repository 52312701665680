.container-dashboard {
    margin-top: 180px;
    text-align: center;
}

.title-dashboard {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 40px;
}

.dashboard-images {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.dashboard-item {
    position: relative;
    width: 300px;
    height: 200px;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.dashboard-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.dashboard-item:hover {
    transform: scale(1.05);
}

/* Affichage constant de l'overlay */
.overlay-dashboard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Couleur foncée constante */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    opacity: 1; /* Toujours visible */
    transition: transform 0.3s ease; /* Animation du zoom uniquement */
}

.sub-title-dashboard {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}
