html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    background-color: white;
}

.colored-part {
    color: #FDB819;
}

/* Animation pour l'apparition progressive avec flou */
@keyframes fadeInWithBlur {
    0% {
        opacity: 0;
        filter: blur(10px);
    }
    100% {
        opacity: 1;
        filter: blur(0);
    }
}

/* Classe fade-in qui applique l'animation */
.fade-in {
    animation: fadeInWithBlur 1.5s ease forwards; /* Durée de 1.5s */
    opacity: 0; /* État initial */
}