/* Header general */
/* ________________________________________________________________________ */

.header {
    margin-top: 150px; /* Assurez-vous que la hauteur de la navbar est compensée */
    position: relative;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
}

.carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.1);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.9s ease-in-out, opacity 0.9s ease-in-out, visibility 0s 0.9s;
}

.carousel-item.active {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    transition: transform 0.9s ease-in-out, opacity 0.9s ease-in-out;
}

.img-header {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

/* Header big title  */
/* ________________________________________________________________________ */

.title-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    padding: 0 50px;
    transform: translateY(-50%);
    z-index: 3;
    text-align: center;
}

.big-title {
    margin: 0 auto;
    color: white;
    font-size: 64px;
    max-width: calc(100% - 150px);
}
/* Header button */
/* ________________________________________________________________________ */

.button-big-title {
    position: absolute;
    top: 115%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 25px;
    background-color: #FDB819;
    color: black;
    border: none;
    border-radius: 50px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-big-title:hover {
    background-color: #ddd;
}

/* Header chevron */
/* ________________________________________________________________________ */

.chevron-left, .chevron-right {
    position: absolute;
    top: 50%;
    width: 250px;
    height: 250px;
    background-color: #ddd;
    opacity: 0.3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 3;
    transition: background-color 0.3s;
}

.chevron-left:hover, .chevron-right:hover {
    opacity: 0.6;
}

.chevron-left {
    left: -140px;
}

.chevron-right {
    right: -140px;
}

.bi-chevron-compact-left {
    color: black;
    margin-left: 130px;
    font-size: 50px;
}

.bi-chevron-compact-right {
    color: black;
    margin-right: 130px;
    font-size: 50px;
}

/* Responsiv header */
/* ________________________________________________________________________ */

@media screen and (min-width: 1040px) and (max-width: 1490px) {
    .big-title {
        font-size: 50px;
        margin-top: -20px;
    }

    .button-big-title {
        font-size: 18px;
    }
}

@media screen and (min-width: 400px) and (max-width: 866px) {
    .header {
        margin-top: 120px;
    }
}

@media screen and (min-width: 700px) and (max-width: 1040px) {
    .big-title {
        font-size: 40px;
    }

    .button-big-title {
        font-size: 16px;
    }
}

@media screen and (min-width: 520px) and (max-width: 700px) {
    .big-title {
        font-size: 30px;
    }

    .button-big-title {
        padding: 8px 20px;
        font-size: 15px;
    }
}

@media screen and (min-width: 470px) and (max-width: 520px) {
    .big-title {
        font-size: 27px;
    }

    .button-big-title {
        padding: 8px 20px;
        font-size: 14px;
    }
}

@media screen and (min-width: 415px) and (max-width: 470px) {
    .big-title {
        font-size: 24px;
    }

    .button-big-title {
        padding: 6px 15px;
        font-size: 13px;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
    .header {
        margin-top: 100px;
    }
}

@media screen and (min-width: 380px) and (max-width: 415px) {
    .big-title {
        margin-top: -40px;
        font-size: 22px;
    }

    .button-big-title {
        padding: 6px 15px;
        font-size: 12px;
    }
}

@media screen and (min-width: 0px) and (max-width: 300px) {
    .header {
        margin-top: 80px;
    }
}

@media screen and (min-width: 290px) and (max-width: 380px) {
    .big-title {
        margin-top: -50px;
        font-size: 18px;
    }

    .button-big-title {
        padding: 4px 10px;
        font-size: 10px;
    }
}

@media screen and (min-width: 170px) and (max-width: 290px) {
    .big-title {
        margin-top: -50px;
        font-size: 10px;
    }

    .button-big-title {
        padding: 4px 10px;
        font-size: 6px;
    }
}

@media screen and (min-width: 0px) and (max-width: 170px) {
    .big-title {
        margin-top: -30px;
        font-size: 8px;
    }

    .button-big-title {
        padding: 4px 10px;
        font-size: 6px;
    }

    .header {
        height: 65vh;
    }
}

/* Responsiv header chevron */
/* ________________________________________________________________________ */

@media screen and (min-width: 540px) and (max-width: 1485px) {
    .chevron-left, .chevron-right {
        width: 200px;
        height: 200px;
    }

    .bi-chevron-compact-left {
        font-size: 30px;
    }
    
    .bi-chevron-compact-right {
        font-size: 30px;
    }

    .chevron-left {
        left: -120px;
    }
    
    .chevron-right {
        right: -120px;
    }

    .title-container {
        position: absolute;
        padding: 0 70px;
        top: 50%;
        transform: translateY(-60%);
    }
    
    .big-title {
        max-width: calc(100% - 10px);
    }
}

@media screen and (min-width: 400px) and (max-width: 540px) {
    .chevron-left, .chevron-right {
        width: 150px;
        height: 150px;
    }

    .bi-chevron-compact-left {
        font-size: 20px;
    }
    
    .bi-chevron-compact-right {
        font-size: 20px;
    }
    
    .chevron-left {
        left: -100px;
    }
    
    .chevron-right {
        right: -100px;
    }

    .bi-chevron-compact-left {
        margin-left: 100px;
    }
    
    .bi-chevron-compact-right {
        margin-right: 100px;
    }

    .title-container {
        position: absolute;
        padding: 0 40px;
        top: 50%;
        transform: translateY(-60%);
    }
    
    .big-title {
        max-width: calc(100% - 10px);
    }
}

@media screen and (min-width: 280px) and (max-width: 400px) {
    .chevron-left, .chevron-right {
        width: 150px;
        height: 130px;
        transform: translateY(-70%);
    }

    .bi-chevron-compact-left {
        font-size: 20px;
    }
    
    .bi-chevron-compact-right {
        font-size: 20px;
    }
    
    .chevron-left {
        left: -110px;
    }
    
    .chevron-right {
        right: -110px;
    }

    .bi-chevron-compact-left {
        margin-left: 100px;
    }
    
    .bi-chevron-compact-right {
        margin-right: 100px;
    }

    .title-container {
        position: absolute;
        padding: 0 50px;
        top: 50%;
        transform: translateY(-60%);
    }
    
    .big-title {
        max-width: calc(150% - 10px);
    }
}

@media screen and (min-width: 190px) and (max-width: 280px) {
    .chevron-left, .chevron-right {
        width: 100px;
        height: 100px;
        transform: translateY(-70%);
    }

    .bi-chevron-compact-left {
        font-size: 15px;
    }
    
    .bi-chevron-compact-right {
        font-size: 15px;
    }
    
    .chevron-left {
        left: -70px;
    }
    
    .chevron-right {
        right: -70px;
    }

    .bi-chevron-compact-left {
        margin-left: 70px;
    }
    
    .bi-chevron-compact-right {
        margin-right: 70px;
    }

    .title-container {
        position: absolute;
        padding: 0 40px;
        top: 50%;
        transform: translateY(-60%);
    }
    
    .big-title {
        max-width: calc(180% - 10px);
    }
}

@media screen and (min-width: 0px) and (max-width: 190px) {
    .chevron-left, .chevron-right {
        width: 70px;
        height: 80px;
        transform: translateY(-65%);
    }

    .bi-chevron-compact-left {
        font-size: 12px;
    }
    
    .bi-chevron-compact-right {
        font-size: 12px;
    }
    
    .chevron-left {
        left: -50px;
    }
    
    .chevron-right {
        right: -50px;
    }

    .bi-chevron-compact-left {
        margin-left: 50px;
    }
    
    .bi-chevron-compact-right {
        margin-right: 50px;
    }

    .title-container {
        position: absolute;
        padding: 0 20px;
        top: 50%;
        transform: translateY(-20%);
    }
    
    .big-title {
        max-width: calc(300% - 10px);
    }
}