.container-wrapper-blog {
    display: flex;
    justify-content: center;
    margin-top: 150px; /* Compense la hauteur de la navbar */
}

.main-container-blog {
    display: flex;
    justify-content: center;
    max-width: 1170px;
    margin: 0 auto; /* Centrer la section blog */
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.main-container-blog-left {
    flex-grow: 1;
    margin-right: 30px; /* Ajout d'un espace entre les articles et le menu */
}

.sidebar-blog {
    margin-top: 30px;
    width: 280px;
    background: white;
    border: solid 1px #ddd;
    flex-shrink: 0; /* Empêche la réduction de la largeur du menu */
}

.category-icon-blog {
    width: 20px;
    height: 25px;
    margin-right: 10px;
}

.big-title-category-blog {
    background: #ddd;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.title-category-blog {
    font-size: 13px;
    font-weight: 450;
    color: #607576;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    background-color: #EEEEEE;
    padding: 5px;
}

.chevron-menu-blog {
    cursor: pointer;
    float: right; /* Alignement à droite du chevron */
}

.chevron-menu-blog:hover {
    color: #FDB819;
}

.sidebar-blog ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-blog {
    display: block; /* Modifier selon les besoins de visibilité */
}

.menu-blog li a {
    color: #607576;
    text-decoration: none;
}

.sub-menu-blog li a {
    color: #607576;
    cursor: pointer;
    font-size: 13px;
    text-decoration: none;
}

.sub-menu-blog li a:hover {
    color: #FDB819;
}

.menu-blog .sub-menu-blog {
    display: none;
    margin-left: 15px; /* Assure que la marge s'applique */
}

.recent-article {
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}

.recent-article-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.recent-article-link:hover .recent-article {
    background-color: #f0f0f0; /* Optional: to highlight the article on hover */
}

.article-image img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    margin-left: 10px;
    object-fit: cover;
}

.article-info {
    flex-grow: 1;
}

.article-title {
    font-size: 13px;
    color: #333;
    margin: 0;
}

.article-date {
    font-size: 12px;
    color: #666;
    margin-left: 5px;
}

.promotion-display-blog {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.promotion-img-blog {
    width: 100%;
    height: auto;
    display: block;
}

.promotion-display-blog::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.promotion-text-blog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 80%;
    z-index: 2;
}

.promotion-title-blog {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.promotion-slogan-blog {
    font-size: 14px;
    margin-top: 10px;
}

@media screen and (min-width: 0px) and (max-width: 1210px) {
    .sidebar-blog-container {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 1180px) {
    .main-container-menu-blog {
        display: none;
    }
}