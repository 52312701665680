.form-contact-container {
    max-width: 800px;
    margin: auto;
    padding: 15px;
}

.form-contact h1 {
    margin-top: 150px; /* Assurez-vous que la hauteur de la navbar est compensée */
    text-align: center;
}

.form-company-name-group {
    display: none;
    opacity: 0;
    transition: opacity 3s ease, visibility 0.3s ease, height 0.5s ease, margin-top 0.5s ease;
    max-height: 0;
    overflow: hidden;
}

.form-company-name-group.visible {
    display: block;
    opacity: 1;
    max-height: 100px; /* Ajustez cette valeur selon la hauteur de votre champ */
}

.form-company-name-group.hidden {
    opacity: 0;
    max-height: 0;
}

.form-group-flex {
    width: 100%;
    display: flex;
    gap: 20px;
    margin-bottom: 8px;
}

.form-group-contact-sub-flex {
    width: 50%;
}

.form-group-contact {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.form-label-contact {
    margin-bottom: 5px;
    font-weight: 600;
}

.form-control-contact {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-label-contact-condition {
    font-size: 15px;
    font-weight: 400;
}

.form-check-input-contact {
    margin-top: 15px;
    margin-bottom: 20px;
}

.form-contact-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.form-contact-button a {
    text-align: center;
    text-decoration: none;
    border: none;
}

.btn-contact {
    width: 45%;
    font-size: 13px;
    font-weight: 500;
    background-color: #454545;
    color: white;
    border: none;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
}

.btn-contact:hover {
    background-color: #fdb819;
}

.alert-success {
    font-size: 18px;
    color: #155724;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
}

/* Pour les écrans moyens (tablettes) */
@media (max-width: 866px) {
    .form-contact h1 {
        margin-top: 120px; /* Ajustez selon vos besoins */
    }
}

/* Pour les écrans moyens (tablettes) */
@media (max-width: 768px) {
    .form-contact-container {
        max-width: 90%;
        padding: 10px;
    }

    .form-contact h1 {
        font-size: 24px;
        margin-top: 130px; /* Ajustez selon vos besoins */
    }

    .form-group-flex {
        flex-direction: column;
        gap: 10px;
    }

    .form-group-contact-sub-flex {
        width: 100%;
    }

    .form-contact-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }

    .btn-contact {
        width: 100%;
    }

    .form-contact-button a {
        width: 93%;
    }
}

/* Pour les petits écrans (smartphones) */
@media (max-width: 480px) {
    .form-contact h1 {
        font-size: 20px;
        margin-top: 140px;
    }

    .form-control-contact {
        padding: 8px;
        font-size: 14px;
    }

    .btn-contact {
        padding: 8px;
        font-size: 12px;
    }

    .alert-success {
        font-size: 16px;
    }
}

/* Pour les très petits écrans */
@media (max-width: 400px) {
    .form-contact h1 {
        margin-top: 110px;
    }
}

/* Pour les très petits écrans */
@media (max-width: 290px) {
    .form-contact h1 {
        font-size: 18px;
        margin-top: 80px;
    }

    .form-control-contact {
        padding: 6px;
        font-size: 12px;
    }

    .btn-contact {
        padding: 6px;
        font-size: 10px;
    }

    .alert-success {
        font-size: 14px;
    }
}