.menu-sheet-container-burger {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 15px;
}

.menu-sheet-burger {
    display: none;
    margin-bottom: -10px;
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #607576;
    cursor: pointer;
    padding: 5px 20px 5px 20px;
    text-transform: uppercase;
    background-color: white;
    border: 3px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: color 0.3s ease, border-color 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.menu-sheet-burger::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: #FDB819;
    z-index: 0;
    transition: width 0.6s ease-in-out, left 0.6s ease-in-out;
}

.menu-sheet-burger:hover::before {
    width: 100%;
    left: 0;
}

.menu-sheet-burger:hover {
    border-color: #FDB819;
    color: white;
}

.menu-sheet-burger span {
    position: relative;
    z-index: 1;
    transition: color 0.3s ease;
}

.menu-sheet-burger:hover span {
    color: white;
}

.container-wrapper-productsheet {
    display: flex;
    justify-content: center;
    margin-top: 150px;
    /* Assurez-vous que la hauteur de la navbar est compensée */
}

.main-container-productsheet {
    display: flex;
    width: 100%;
    max-width: 1180px;
}

.product-detail-container {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.product-detail-left {
    width: 50%;
    margin-right: 10px;
}

.product-detail-right {
    width: 50%;
}

.productsheet-name {
    font-size: 22px;
    font-weight: 700;
}

.display-productsheet-weight {
    margin-top: -2px;
    margin-bottom: -1px;
    font-size: 14px;
    color: #607576;
}

.original-price {
    font-size: 22px;
    font-weight: 500;
    color: #fdb819;
}

.original-price-product-sheet {
    text-decoration: line-through;
    color: #607576;
    font-size: 15px;
    margin-right: 10px;
}

.discounted-price-product-sheet {
    color: #fdb819;
    font-weight: 500;
    font-size: 22px;
}

.original-price-product-sheet-cross {
    text-decoration: line-through;
    color: #607576;
    font-size: 12px;
}

.discounted-price-product-sheet-cross {
    color: #fdb819;
    font-weight: 600;
    font-size: 14px;
}

.bi-file-earmark-pdf-fill {
    font-size: 18px;
    color: red;
}

.product-sheet-link {
    font-size: 13px;
    text-decoration: none;
    color: #4F4F4F;
}

.productsheet-short-description {
    text-align: justify;
    padding-right: 10px;
    line-height: 1.5;
    font-size: 14px;
    color: #4F4F4F;
}

.product-quantity-and-price {
    display: flex;
    align-items: center;
    gap: 130px;
}

.product-quantity-and-price input {
    width: 40px;
}

.quantity-and-input {
    margin-bottom: 10px;
}

.quantity-and-input label {
    font-size: 16px;
    color: #4F4F4F;
}

.add-to-cart-button {
    background-color: #fdb819;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
}

.add-to-cart-button:hover {
    background-color: #ddd;
}

.product-image img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ddd;
    margin-top: 20px;
}

.product-zoom button {
    background-color: white;
    color: #4F4F4F;
    border: 2px solid #DDDDDD;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.product-zoom button:hover {
    background-color: #ddd;
    color: white;
}

.related-products {
    display: flex;
    justify-content: center;
}

.related-products img {
    width: 80px;
    height: 80px;
    border: 1px solid #ddd;
    margin: 0 5px;
}

.related-products img:hover {
    border: 1px solid #fdb819;
    cursor: pointer;
}

/*------------------------------------*/

.frequently-asked-question {
    width: 98%;
    margin-left: 8px;
    margin-top: 30px;
    border: 1px solid #ddd;
}

.faq-question {
    border: 1px solid #ddd;
}

.faq-title h2 {
    text-align: center;
    background-color: #ddd;
    padding: 10px;
    margin-top: -1px;
    margin-bottom: -5px;
}

.faq-container-title {
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.chevron-faq-question {
    font-size: 0.8em;
    margin-right: 10px;
}

.faq-question-title {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
    margin-right: 20px;
}

.faq-question-answer {
    font-size: 13px;
    font-weight: 400;
    margin-left: 20px;
    margin-right: 20px;
    display: none;
}


/*------------------------------------*/

.frequently-bought-together-left {
    width: 60%;
}

.frequently-bought-together-right {
    width: 40%;
}

.frequently-bought-together-container {
    display: flex;
    align-items: center;
    margin: 10px;
}

.frequently-bought-together-container h2 {
    font-size: 22px;
}

.frequently-bought-together {
    display: flex;
    align-items: center;
}

.frequently-bought-together-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    width: 100px;
    margin: 0;
    /* Enlever tout espace extérieur */
    padding: 0;
    /* Enlever tout espace intérieur */
}

.product-checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.maintenance-checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.maintenance-devis-checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.devis-checkbox {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.frequently-bought-together-product img {
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
}

.frequently-bought-together-product img:hover {
    border: 1px solid #fdb819;
}

.frequently-bought-together-name {
    font-size: 14px;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    /* Enlever les marges pour réduire l'espace */
}

.frequently-bought-together-name a {
    text-decoration: none;
    /* Retirer le soulignement */
    color: #4F4F4F;
    /* Couleur par défaut des liens */
    transition: color 0.3s ease;
    /* Optionnel : transition pour rendre le changement de couleur plus fluide */
}

.frequently-bought-together-name a:hover {
    color: #fdb819 !important;
    /* Couleur du lien au survol */
}

.frequently-bought-together-name a:visited {
    color: #4F4F4F;
    /* Couleur des liens déjà visités */
}

.frequently-bought-together-name a:active {
    color: #4F4F4F;
    /* Couleur des liens actifs */
}

p.frequently-bought-together-weight {
    font-size: 10px;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.frequently-bought-together-price {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    color: #fdb819;
    margin: 0;
    /* Enlever les marges pour réduire l'espace */
}

.plus-sign {
    font-size: 2em;
    margin: 0 10px;
    font-weight: bold;
    color: #888;
    transform: translateY(-60%);
}

.plus-sign-maintenance {
    font-size: 2em;
    margin: 0 10px;
    font-weight: bold;
    color: #888;
    transform: translateY(-40%);
}

.total-price-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.total-price {
    font-size: 1.2em;
}

.total-price p {
    font-size: 16px;
    color: #4F4F4F;
}

.total-price span {
    font-size: 22px;
    font-weight: 600;
    color: #fdb819 !important;
}

.add-all-to-cart-button {
    background-color: #fdb819;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.add-all-to-cart-button-maintenance {
    background-color: #fdb819;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.add-all-to-cart-button:hover {
    background-color: #ddd;
}

.add-all-to-cart-button-maintenance:hover {
    background-color: #ddd;
}

/*------------------------------------*/
.productsheet-description {
    margin-top: 25px;
    margin-left: 8px;
}

/* Style the tab */
.tab {
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;
    width: 100%;
    display: flex; /* Utilisation de flexbox pour l'uniformité */
    justify-content: space-evenly; /* Espacement égal entre les boutons */
}

.tabcontent a {
    text-decoration: none;
    color: black;
    font-weight: 500;
}

/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
    font-weight: 600;
    flex: 1; /* Chaque bouton occupe une part égale */
    text-align: center;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
    width: 100%;
    box-sizing: border-box; /* S'assure que la largeur inclut le padding */
}

@media screen and (min-width: 0px) and (max-width: 1300px) {   
    .menu-sheet-burger {
        display: block;
    }
}

@media screen and (min-width: 700px) and (max-width: 866px) {
    .menu-sheet-container-burger {
        margin-top: -10px;
    }
}

@media screen and (min-width: 700px) and (max-width: 1300px) {
    .productsheet-description {
        margin-left: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-container-productsheet {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 768px) {
    .tab {
        flex-direction: column; /* Les onglets passent en colonne */
        align-items: stretch; /* Les boutons remplissent la largeur */
    }

    .tab button {
        text-align: left;
        padding: 12px; /* Réduction du padding */
        font-size: 15px; /* Réduction de la taille de la police */
    }

    .tabcontent {
        padding: 10px; /* Plus de padding pour le contenu */
    }
}

@media screen and (min-width: 0px) and (max-width: 700px) {
    .productsheet-description {
        margin-left: 0px;
    }

    .container-wrapper-productsheet {
        margin-top: 110px;
        /* Assurez-vous que la hauteur de la navbar est compensée */
    }

    .main-container-productsheet {
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    .product-detail-container {
        flex-direction: column;
    }

    .product-detail-left {
        width: 100%;
        justify-content: center;
        margin-right: 10px;
    }

    .product-detail-right {
        width: 100%;
        justify-content: center;
    }

    .productsheet-short-description {
        padding-right: 0px;
    }
    

    .frequently-asked-question {
        width: 100%;
        margin-left: 0px;
    }

    .frequently-bought-together-container {
        flex-direction: column;
        text-align: center;
    }

    .frequently-bought-together {
        justify-content: center;
    }

    .frequently-bought-together-container h2 {
        font-size: 20px;
    }

    .frequently-bought-together-left {
        width: 100%;
    }

    .total-price p {
        font-size: 23px;
        font-weight: 600;
    }

    .total-price-container {
        margin-top: 20px;
    }
}

@media screen and (min-width: 400px) and (max-width: 700px) {
    .menu-sheet-container-burger {
        margin-top: 30px;
    }
}

@media screen and (min-width: 0px) and (max-width: 525px) {
    .frequently-bought-together {
        justify-content: center;
        flex-direction: column;
    }

    .frequently-bought-together-container h2 {
        font-size: 22px;
        text-align: left;
    }

    .frequently-bought-together-left {
        width: 100%;
    }

    .frequently-bought-together-right {
        width: 100%;
    }

    .frequently-bought-together-product {
        width: 350px;
    }

    .frequently-bought-together-product img {
        width: 350px;
        height: 350px;
    }

    .frequently-bought-together-name {
        font-size: 18px;
    }

    .frequently-bought-together-price {
        font-size: 20px;
    }

    .original-price-product-sheet-cross {
        font-size: 18px;
    }

    .discounted-price-product-sheet-cross {
        font-size: 20px;
    }

    .total-price p {
        font-size: 23px;
        font-weight: 600;
    }
    
    .total-price span {
        font-size: 22px;
        font-weight: 600;
        color: #fdb819 !important;
    }    

    .plus-sign {
        transform: translateY(0%);
    }

    .add-all-to-cart-button {
        width: 350px;
    }

    .add-all-to-cart-button-maintenance {
        width: 350px;
    }
}

@media screen and (max-width: 480px) {
    .tab button {
        padding: 10px;
        font-size: 14px;
    }

    .tabcontent {
        padding: 8px;
        font-size: 14px; /* Taille de police ajustée pour le contenu */
    }
}

@media screen and (min-width: 290px) and (max-width: 400px) {
    .menu-sheet-container-burger {
        margin-top: 10px;
    }
}

@media screen and (min-width: 0px) and (max-width: 290px) {
    .menu-sheet-container-burger {
        margin-top: -10px;
    }
}