/* Dropdown styles for "Mon compte" */
.menu-account .dropdown {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    /* Augmentez le z-index ici */
    list-style: none;
    padding: 0;
    margin: 0;
    width: 160px;
}

.menu-account .dropdown li {
    padding: 2px;
}

.menu-account .dropdown li a {
    color: black;
    text-decoration: none;
    display: block;
}

.menu-account .dropdown li a:hover {
    background-color: #f1f1f1;
}

/* Show dropdown on hover */
.menu-account:hover .dropdown {
    display: block;
}

/* Optional: Styling the chevron icon */
.menu-account>a {
    display: flex;
    align-items: center;
}

.menu-account>a i {
    margin-left: 8px;
    font-size: 12px;
}

/* Dropdown styles */
.menu-contact .dropdown {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 10000;
    /* Augmentez le z-index ici */
    list-style: none;
    padding: 0;
    margin: 0;
    width: 250px;
}

.menu-contact .dropdown li {
    padding: 8px;
}

.menu-contact .dropdown li a {
    color: black;
    text-decoration: none;
    display: block;
}

.menu-contact .dropdown li a:hover {
    background-color: #f1f1f1;
}

/* Show dropdown on hover */
.menu-contact:hover .dropdown {
    display: block;
}

/* Optional: Styling the chevron icon */
.menu-contact>a {
    display: flex;
    align-items: center;
}

.menu-contact>a i {
    margin-left: 8px;
    font-size: 12px;
}

/* General navbar */
/* ________________________________________________________________________ */

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    /* Assurez-vous que la navbar occupe toute la largeur */
    background-color: white;
    /* Fond blanc */
    z-index: 1000;
    /* Pour la garder au-dessus du reste */
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Ombre légère pour la distinction */
}

.bi-chevron-down {
    margin-left: 10px;
    font-size: 10px;
}

.navbar a {
    text-decoration: none;
    color: black;
}

.nav-bottom {
    display: flex;
    height: 100px;
    justify-content: center;
}

.nav-top {
    background-color: black;
}

.nav-top2 {
    justify-content: center;
    display: flex;
}

.logo-and-menu {
    display: flex;
}

.logo-libre-acces {
    cursor: pointer;
}

/* Navbar assurance */
/* ________________________________________________________________________ */

.navbar-assurance {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    gap: 550px;
}

.bi-search {
    margin-right: 5px;
    color: white;
}

.navbar-assurance-right {
    transform: translateX(-40%);
    display: flex;
    list-style-type: none;
}

.navbar-assurance-right li a {
    color: white;
}

.separator-nav-assurance {
    margin-left: 10px;
    margin-right: 10px;
}

/* Logo */
/* ________________________________________________________________________ */

.logo {
    width: 250px;
    margin-right: 30px;
}

/* logo scrolling strip */
/* ________________________________________________________________________ */

@keyframes scroll-left {
    0% {
        transform: translateX(130%);
    }

    100% {
        transform: translateX(-200%);
    }
}

.scrolling-logo-container {
    transform: translateY(-280%);
    margin-left: 64px;
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.scrolling-logo {
    color: #607576;
    font-weight: 600;
    transform: translateX(130%);
    /* Ajoute une transition initiale pour l'effet de démarrage en douceur */
    transition: transform 0.5s ease;
}

.start-scrolling {
    animation: scroll-left 10s linear infinite;
}

/* Menu Navbar */
/* ________________________________________________________________________ */

.menu-nav {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;
    gap: 30px;
}

.menu-nav li a {
    font-size: 16px;
    font-weight: 700;
    color: black;
}

/* Navbar cart */
/* ________________________________________________________________________ */

.cart-container {
    margin-left: 350px;
    margin-top: -50px;
    background-color: #e3c121;
}

.cart {
    padding: 10px;
    z-index: 1000;
}

.cart li {
    list-style-type: none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

/* Style pour le bouton Admin */
.admin-url {
    position: absolute;
    right: 20px;
    /* Ajustez la distance à partir du bord droit */
    top: 50%;
    /* Pour centrer verticalement */
    background-color: #607576;
    /* Couleur de fond */
    border: none;
    width: 50px;
    /* Largeur et hauteur égales pour un bouton rond */
    height: 50px;
    border-radius: 50%;
    /* Rend le bouton rond */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1000;
    /* Assurez-vous que le bouton est au-dessus */
    transition: background-color 0.3s ease;
    /* Transition douce pour le survol */
}

.admin-url a {
    font-size: 20px;
    /* Taille de l'icône */
    color: white;
    /* Couleur de l'icône */
}

.admin-url:hover {
    background-color: black;
    /* Couleur au survol */
}

/* Menu BURGER */
/* ________________________________________________________________________ */


.bi-list {
    cursor: pointer;
}

.bi-x-circle {
    position: absolute;
    color: white;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
    font-size: 27px;
    cursor: pointer;
}

.bi-x-circle:hover {
    color: #FDB819;
}

.burger-menu-container {
    background-color: rgba(96, 117, 118);
    width:30%;
    padding: 30px;
    position: absolute; /* Positionne le menu de manière indépendante */
    right: 0; /* Aligne le menu tout à droite */
    top: 0; /* Aligné en haut */
    height: 100vh; /* Prend toute la hauteur de l'écran */
    display: none; /* Masquer par défaut */
    flex-direction: column;
    z-index: 2;
    border-radius: 30px 0 0 0;
    overflow-y: auto; /* Active le scroll vertical si nécessaire */
    scrollbar-width: thin; /* Réduit la largeur de la scrollbar */
    scrollbar-color: #FDB819 transparent; /* Personnalise la couleur */
}

.burger-menu-container.active {
    display: flex;
}

/* Personnalisation de la scrollbar pour Webkit (Chrome, Safari) */
.burger-menu-container::-webkit-scrollbar {
    width: 5px; /* Épaisseur de la scrollbar */
}

.burger-menu-container::-webkit-scrollbar-track {
    background: transparent; /* Fond de la scrollbar */
}

.burger-menu-container::-webkit-scrollbar-thumb {
    background-color: #FDB819; /* Couleur de la barre */
    border-radius: 10px; /* Coins arrondis */
}

.burger-menu-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.burger-menu-container li {
    list-style: none;
    padding-right: 30px;
    width: 100%
}

.burger-menu-container a {
    color: white;
    text-decoration: none;
}

.dropdown-burger li {
    margin-left: 15px;
}

.menu-search-burger {
    margin-top: 50px;
}

.menu-account-burger {
    margin-top: 50px;
}

.link-menu-burger {
    margin-top: 50px;
}

.menu-contact-burger {
    margin-top: 50px;
    margin-bottom: 50px;
}

.cart-container-burger {
    margin-top: 50px;
}

.link-menu-burger-li a:hover {
    color: #FDB819;
}
.dropdown-burger a:hover {
    color: #FDB819;
}

.number-of-items-burger:hover {
    color: #FDB819;
}

.dropdown-burger {
    display: none;
    margin-left: 15px;
}

.menu-account-burger.active .dropdown-burger,
.menu-contact-burger.active .dropdown-burger {
    display: block; /* Affiche les sous-menus lorsqu'ils sont actifs */
}

/* Responsive pour adapter la largeur */
@media screen and (max-width: 700px) {
    .burger-menu-container { width: 40%; }
}

@media screen and (max-width: 520px) {
    .burger-menu-container { width: 50%; }
}

@media screen and (max-width: 420px) {
    .burger-menu-container { width: 60%; }
}

@media screen and (max-width: 345px) {
    .burger-menu-container { width: 70%; }
}

@media screen and (max-width: 300px) {
    .burger-menu-container { width: 75%; }
}

/* General responsiv navbar */
/* ________________________________________________________________________ */

@media screen and (min-width: 1010px) and (max-width: 1330px) {
    .admin-url {
        right: 200px;
    }
}

@media screen and (min-width: 950px) and (max-width: 1010px) {
    .admin-url {
        right: 150px;
    }
}

@media screen and (min-width: 900px) and (max-width: 950px) {
    .admin-url {
        right: 45px;
        top: 80%;
    }
}

@media screen and (min-width: 866px) and (max-width: 900px) {
    .admin-url {
        right: 20px;
        top: 80%;
    }
}

@media screen and (min-width: 866px) {
    .bi-list {
        display: none;
    }
}

@media screen and (min-width: 0px) and (max-width: 866px) {
    .navbar-assurance li {
        position: absolute;
    }

    .navbar-assurance-right li {
        display: none;
    }

    .menu-nav {
        display: none;
    }

    .cart-container {
        display: none;
    }

    .bi-list {
        top: 20px;
        position: absolute;
        right: 0;
        margin-right: 10px;
        font-size: 35px;
    }

    .admin-url {
        right: 10px;
        top: 85%;
    }
}

@media screen and (min-width: 0px) and (max-width: 430px) {
    .admin-url {
        right: 10px;
        top: 85%;
        width: 40px;
        height: 40px;
    }
}

@media screen and (min-width: 290px) and (max-width: 400px) {
    .logo {
        width: 200px;
    }

    .scrolling-logo-container {
        transform: translateY(-235%);
        margin-left: 52px;
        width: 143px;
    }

    .nav-bottom {
        height: 80px;
    }
}

@media screen and (min-width: 170px) and (max-width: 290px) {
    .logo {
        width: 130px;
    }

    .scrolling-logo-container {
        transform: translateY(-250%);
        margin-left: 33px;
        width: 92px;
        font-size: 10px;
    }

    .nav-bottom {
        height: 55px;
    }

    .navbar-assurance li {
        padding: 0px;
        font-size: 10px;
        margin-bottom: 8px;
    }

    .bi-list {
        top: 25px;
        margin-right: 10px;
        font-size: 25px;
    }
}

@media screen and (min-width: 0px) and (max-width: 170px) {
    .logo {
        width: 70px;
    }

    .scrolling-logo-container {
        transform: translateY(-260%);
        margin-left: 18px;
        width: 49px;
        font-size: 7px;
    }

    .nav-bottom {
        height: 30px;
    }

    .navbar-assurance li {
        padding: 8px;
        font-size: 7px;
    }

    .bi-list {
        top: 25px;
        margin-right: 7px;
        font-size: 18px;
    }
}

@media screen and (min-width: 170px) and (max-width: 350px) {
    .navbar-assurance li {
        margin-top: 10px;
        font-size: 10px;
    }
}

/* Responsiv navbar */
/* ________________________________________________________________________ */

@media screen and (min-width: 1165px) and (max-width: 1185px) {
    .cart-container {
        margin-left: 290px;
    }

    .navbar-assurance-right {
        transform: translateX(-40%);
    }

    .navbar-assurance {
        gap: 500px;
    }
}

@media screen and (min-width: 1145px) and (max-width: 1165px) {
    .cart-container {
        margin-left: 290px;
    }

    .navbar-assurance-right {
        transform: translateX(-40%);
    }

    .navbar-assurance {
        gap: 520px;
    }
}

@media screen and (min-width: 1040px) and (max-width: 1145px) {
    .cart-container {
        margin-left: 290px;
    }

    .navbar-assurance-right {
        transform: translateX(-40%);
    }

    .navbar-assurance {
        gap: 510px;
    }
}

@media screen and (min-width: 1120px) and (max-width: 1140px) {
    .cart-container {
        margin-left: 270px;
    }

    .navbar-assurance-right {
        transform: translateX(-40%);
    }

    .navbar-assurance {
        gap: 500px;
    }

    .img {
        margin-right: 30px;
    }
}

@media screen and (min-width: 1105px) and (max-width: 1120px) {
    .cart-container {
        margin-left: 250px;
    }

    .navbar-assurance {
        gap: 490px;
    }

    .img {
        margin-right: 30px;
    }
}

@media screen and (min-width: 1075px) and (max-width: 1105px) {
    .cart-container {
        margin-left: 220px;
    }

    .navbar-assurance {
        gap: 450px;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1075px) {
    .cart-container {
        margin-left: 200px;
    }

    .navbar-assurance {
        gap: 430px;
    }

    .img {
        margin-right: 30px;
    }
}

@media screen and (min-width: 1035px) and (max-width: 1050px) {
    .cart-container {
        margin-left: 180px;
    }

    .navbar-assurance {
        gap: 420px;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1035px) {
    .cart-container {
        margin-left: 180px;
    }

    .navbar-assurance {
        gap: 410px;
    }
}

@media screen and (min-width: 1010px) and (max-width: 1020px) {
    .cart-container {
        margin-left: 160px;
    }

    .navbar-assurance {
        gap: 390px;
    }
}

@media screen and (min-width: 990px) and (max-width: 1010px) {
    .cart-container {
        margin-left: 140px;
    }

    .navbar-assurance {
        gap: 380px;
    }
}

@media screen and (min-width: 970px) and (max-width: 990px) {
    .cart-container {
        margin-left: 120px;
    }

    .navbar-assurance {
        gap: 350px;
    }
}

@media screen and (min-width: 950px) and (max-width: 970px) {
    .cart-container {
        margin-left: 100px;
    }

    .navbar-assurance {
        gap: 330px;
    }
}

@media screen and (min-width: 930px) and (max-width: 950px) {
    .cart-container {
        margin-left: 80px;
    }

    .navbar-assurance {
        gap: 310px;
    }
}

@media screen and (min-width: 910px) and (max-width: 930px) {
    .cart-container {
        margin-left: 60px;
    }

    .navbar-assurance {
        gap: 290px;
    }
}

@media screen and (min-width: 890px) and (max-width: 910px) {
    .cart-container {
        margin-left: 40px;
    }

    .navbar-assurance {
        gap: 270px;
    }
}

@media screen and (min-width: 865px) and (max-width: 890px) {
    .cart-container {
        margin-left: 50px;
    }

    .navbar-assurance-right {
        transform: translateX(-40%);
    }

    .navbar-assurance {
        gap: 250px;
    }

    .img {
        margin-right: 10px;
    }
}

@media screen and (min-width: 401px) and (max-width: 865px) {
    .scrolling-logo-container {
        transform: translateY(-275%);
    }
}

@media screen and (min-width: 350px) and (max-width: 600px) {
    .navbar-assurance li {
        font-size: 12px;
    }
}

@media screen and (min-width: 280px) and (max-width: 350px) {
    .navbar-assurance li {
        margin-bottom: 10px;
    }
}