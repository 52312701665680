.container-private-shop {
    margin-top: 180px;
    text-align: center;
    padding: 40px;
    background-color: #f7f7f7;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.3s ease;
}

.container-private-shop:hover {
    transform: scale(1.02);
}

.title-private-shop {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    color: #333;
    letter-spacing: 1px;
}

.text-private-shop {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #555;
    line-height: 1.6;
}

.img-private-shop {
    width: 100%;
    height: 200px;
    max-width: 600px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    object-fit: cover;
}

.img-private-shop:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
