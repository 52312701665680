.financial-help-navigation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.financial-help-container {
    overflow: hidden;
    width: 100%;
    max-width: 1800px;
    position: relative;
}

.financial-help-posts {
    display: flex;
    overflow-x: hidden;
    /* Masque le débordement horizontal */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding-bottom: 20px;
    gap: 50px;
}

.financial-help-posts::-webkit-scrollbar {
    display: none;
}

.financial-help-chevron {
    cursor: pointer;
    font-size: 24px;
    color: #607576;
    background-color: #EEEEEE;
    position: relative;
    transform: translateY(-20%);
    opacity: 0.8;
    padding: 10px;
    z-index: 5;
}

#financial-help-prev {
    left: 30px;
}

#financial-help-next {
    right: 30px;
}

.financial-help-chevron:hover {
    font-size: 28px;
}

.img-financial-help-post {
    background-color: white;
    width: 380px;
    height: 280px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.img-financial-help-post:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 1750px) and (max-width: 1850px) {
    .financial-help-container {
        max-width: 1700px;
    }
}

@media screen and (min-width: 1650px) and (max-width: 1750px) {
    .financial-help-container {
        max-width: 1600px;
    }
}

@media screen and (min-width: 1550px) and (max-width: 1650px) {
    .financial-help-container {
        max-width: 1500px;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1550px) {
    .financial-help-container {
        max-width: 1400px;
    }
}

@media screen and (min-width: 1350px) and (max-width: 1450px) {
    .financial-help-container {
        max-width: 1300px;
    }
}

@media screen and (min-width: 1250px) and (max-width: 1350px) {
    .financial-help-container {
        max-width: 1200px;
    }
}

@media screen and (min-width: 1150px) and (max-width: 1250px) {
    .financial-help-container {
        max-width: 1100px;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1150px) {
    .financial-help-container {
        max-width: 1000px;
    }
}

@media screen and (min-width: 950px) and (max-width: 1050px) {
    .financial-help-container {
        max-width: 900px;
    }
}

@media screen and (min-width: 850px) and (max-width: 950px) {
    .financial-help-container {
        max-width: 800px;
    }
}

@media screen and (min-width: 580px) and (max-width: 900px) {
    .financial-help-container {
        width: 80%;
    }

    .img-financial-help-post {
        width: 350px;
        height: 250px;
    }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
    .financial-help-container {
        max-width: 700px;
    }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
    .financial-help-container {
        max-width: 600px;
    }
}

@media screen and (min-width: 550px) and (max-width: 650px) {
    .financial-help-container {
        max-width: 500px;
    }
}

@media screen and (min-width: 0px) and (max-width: 580px) {
    .financial-help-container {
        width: 80%;
    }

    .img-financial-help-post {
        width: 250px;
        height: 150px;
    }
}