/* General product-display-on-quote-home */
/* ________________________________________________________________________ */
.total-product-display {
    display: flex;
    overflow: hidden;
    width: 100%; /* Affiche seulement la première section du conteneur */
    scroll-behavior: smooth;
}

.product-display {
    flex: 1 0 33.33%; /* Chaque produit prend un tiers de la largeur pour afficher trois éléments */
    position: relative;
    max-width: 33.33%;
    height: 400px;
}

.img-display {
    width: 100%;
    height: 400px;
    object-fit: cover;
    z-index: 1;
}

.product-display-overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: flex; /* Activation du mode Flexbox */
    flex-direction: column; /* Aligner les enfants verticalement */
    align-items: center; /* Centrer horizontalement */
    justify-content: center; /* Centrer verticalement */
    text-align: center; /* Centrer le texte */
    background-color: rgba(0, 0, 0, 0.6); /* Fond semi-transparent */
    padding: 20px;
    transition: opacity 0.5s ease;
    opacity: 0; /* Par défaut, caché */
}

.title-product-display1 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: -15px;
    color: #FDB819;
}

.slogan-product-display1 {
    font-size: 13px;
    font-weight: 400;
    color: white;
}

.button-product-display1 {
    background-color: #FDB819;
    border: none;
    border-radius: 50px;
    padding: 15px 32px;
    font-size: 16px;
    font-weight: 700;
    z-index: 3;
    cursor: pointer;
    transition: background-color 0.3s;
    color: black; /* Couleur du texte pour les liens normaux */
    text-decoration: none; /* Retirer le soulignement si nécessaire */
}

.button-product-display1:hover {
    background-color: #ddd;
}

/* Pour les liens visités */
.button-product-display1:visited {
    color: black; /* Couleur noire pour les liens visités */
    text-decoration: none; /* Assurer qu'il n'y a pas de soulignement sur les liens visités */
}

.total-button-product-shop-product-display {
    text-align: center;
}

/* Reponsiv product-display-on-quote-home */
/* ________________________________________________________________________ */


@media screen and (min-width: 1250px) and (max-width: 1450px) {
    .product-display {
        flex: 1 0 100%;
        max-width: 50%;
        height: 500px;
    }

    .img-display {
        height: 500px;
    }

    .title-product-display1 {
        font-size: 30px;
    }

    .slogan-product-display1 {
        font-size: 22px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1250px) {
    .product-display {
        flex: 1 0 100%;
        max-width: 50%;
        height: 400px;
    }

    .img-display {
        height: 400px;
    }

    .title-product-display1 {
        font-size: 25px;
    }

    .slogan-product-display1 {
        font-size: 18px;
    }

    .button-product-display1 {
        padding: 12px 20px;
        font-size: 14px;
    }
}

@media screen and (min-width: 650px) and (max-width: 1000px) {
    .product-display {
        flex: 1 0 100%;
        max-width: 100%;
        height: 400px;
    }

    .img-display {
        height: 400px;
    }

    .title-product-display1 {
        font-size: 30px;
    }

    .slogan-product-display1 {
        font-size: 22px;
    }

    .button-product-display1 {
        padding: 12px 20px;
        font-size: 12px;
    }
}

@media screen and (min-width: 480px) and (max-width: 650px) {
    .product-display {
        flex: 1 0 100%;
        max-width: 100%;
        height: 400px;
    }

    .img-display {
        height: 400px;
    }

    .title-product-display1 {
        font-size: 25px;
    }

    .slogan-product-display1 {
        font-size: 18px;
    }

    .button-product-display1 {
        padding: 8px 16px;
        font-size: 12px;
    }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
    .product-display {
        flex: 1 0 100%;
        max-width: 100%;
        height: 400px;
    }

    .img-display {
        height: 400px;
    }

    .title-product-display1 {
        font-size: 20px;
    }

    .slogan-product-display1 {
        font-size: 14px;
    }

    .button-product-display1 {
        padding: 8px 16px;
        font-size: 12px;
    }
}