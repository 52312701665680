.form-repair-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.form-repair {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
}

.form-repair h1 {
    margin-top: 150px; /* Assurez-vous que la hauteur de la navbar est compensée */
    text-align: center;
    margin-bottom: 10px;
}

.form-repair-left-and-right {
    display: flex;
    justify-content: space-between;
}

.form-repair-left, .form-repair-right {
    width: 49%;
}

.form-label-repair {
    margin-bottom: 5px;
    font-weight: 600;
}

.form-group-repair {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.form-label-repair-condition {
    font-size: 15px;
    font-weight: 400;
}

.form-check-input-repair {
    margin-top: 15px;
    margin-bottom: 20px;
}

.form-control-repair {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.btn-repair {
    width: 100%;
    background-color: #454545;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.btn-repair:hover {
    background-color: #fdb819;
    color: white;
}

/* Responsive adjustments */

/* Pour les écrans moyens (tablettes) */

@media (max-width: 866px) {
    .form-repair h1 {
        margin-top: 120px; /* Ajustez si nécessaire */
    }
}

@media (max-width: 768px) {
    .form-repair-container {
        max-width: 90%;
        padding: 15px;
    }

    .form-repair h1 {
        font-size: 24px;
        margin-top: 120px; /* Ajustez si nécessaire */
    }

    .form-repair-left-and-right {
        flex-direction: column; /* Passe en colonne pour les tablettes */
        gap: 20px;
    }

    .form-repair-left, .form-repair-right {
        width: 100%; /* Utilise toute la largeur */
    }

    .btn-repair {
        padding: 10px 15px;
        font-size: 14px;
    }
}

/* Pour les petits écrans (smartphones) */
@media (max-width: 480px) {
    .form-repair h1 {
        font-size: 20px;
        margin-top: 120px;
    }

    .form-control-repair {
        padding: 8px;
        font-size: 14px;
    }

    .btn-repair {
        padding: 8px 10px;
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .form-repair h1 {
        font-size: 18px;
        margin-top: 100px;
    }
}

/* Pour les très petits écrans */
@media (max-width: 290px) {
    .form-repair h1 {
        font-size: 17px;
        margin-top: 80px;
    }

    .form-control-repair {
        padding: 6px;
        font-size: 12px;
    }

    .btn-repair {
        padding: 6px 10px;
        font-size: 10px;
    }
}
