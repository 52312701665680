.sidebar-shop {
    margin-top: 30px;
    width: 280px;
    background: white;
    display: none; /* Caché par défaut */
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.sidebar-shop.show {
    display: block;
    opacity: 1;
    transform: translateX(0);
}

.bordered {
    border: solid 1px #ddd;
}

.category-icon {
    width: 20px;
    height: 25px;
    margin-right: 10px;
}

.title-category-shop {
    background: #ddd;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.product-title {
    font-size: 13px;
    font-weight: 450;
    color: #607576;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    background-color: #EEEEEE;
}

.product-title-filtered {
    font-size: 13px;
    font-weight: 450;
    color: #607576;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
}

.product-title-filtered a {
    text-decoration: none;
    color: inherit;
}

.product-title span {
    display: block;
    padding: 5px 0;
}

.product-title-filtered span {
    display: block;
    padding: 2px 0;
}

.product-title-filtered a:hover {
    color: #FDB819;
}

.product-title-filtered span:hover {
    color: #FDB819;
}

.menu-product {
    padding-left: 15px;
    color: #607576;
    cursor: pointer;
    font-size: 13px;
}

.menu-product a {
    text-decoration: none;
    color: inherit;
}

.sub-menu li:hover {
    color: #FDB819;
}

.chevron-product-shop {
    margin-right: 10px;
    cursor: pointer;
}

.chevron-product-shop:hover {
    color: #FDB819;
}

.sidebar-shop ul {
    border-bottom: 0.1px solid #ddd;
}

.sidebar-shop ul, .sub-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-shop ul li:last-child, .sub-sub-menu li:last-child {
    border-bottom: none;
}

.sub-menu {
    display: none;
}

.sub-menu li {
    border-bottom: none;
}

.total-blog-post-menu {
    margin: 10px;
    text-align: left;
}

.image-container-blog-post-menu img {
    width: 100%;
    height: auto;
}

.title-blog-post-menu {
    font-size: 14px;
    color: #607576;
}

.blog-post-content-menu {
    font-size: 12px;
    color: #607576;
    margin-bottom: 10px;
}

.read-more-menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.read-more-menu a {
    display: block;
    width: 90%;
    text-align: center;
    font-size: 12px;
    background-color: #454545;
    color: white;
    text-decoration: none;
    font-weight: 600;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.read-more-menu a:hover {
    background-color: #fdb819;
    color: white;
    text-decoration: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.promotion-display {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.promotion-img {
    width: 100%;
    height: auto;
    display: block;
}

.promotion-display::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fond noir avec opacité à 60% */
    z-index: 1;
}

.promotion-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    width: 80%;
    z-index: 2;
}

.promotion-slogan {
    font-size: 14px;
    margin-top: 10px;
}

.blog-post-menu-backup-text, .promotion-backup-text {
    text-align: center;
}

.promotion-button {
    margin-top: 10px;
}

.promotion-button a {
    display: inline-block;
    text-align: center;
    font-size: 12px;
    background-color: #fdb819;
    color: white;
    text-decoration: none;
    font-weight: 600;
    padding: 10px 15px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.promotion-button a:hover {
    background-color: #454545;
    color: white;
    text-decoration: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.closed-menu-burger-shop:hover {
    color: #FDB819;
}

@media screen and (min-width: 1300px) {
    .closed-menu-burger-shop {
        display: none;
    }

    .sidebar-shop {
        display: block !important;
        opacity: 1;
        transform: none;
    }
}

@media screen and (min-width: 866px) and (max-width: 1300px) {
    .sidebar-shop {
        position: absolute;
        top: 120px;
        right: 0;
        width: 90%;
        z-index: 1000;
    }

    .sidebar-shop-container-filter {
        display: none;
    }

    .sidebar-shop-container-display-promotion {
        display: none;
    }

    .closed-menu-burger-shop {
        position: absolute;
        top: 5px;
        right:20px;
        font-size: 1.8em;
        color: #607576;
        cursor: pointer;
    }
}

@media screen and (min-width: 400px) and (max-width: 866px) {
    .sidebar-shop {
        position: absolute;
        top: 100px;
        right: 0;
        width: 90%;
        z-index: 1000;
        cursor: pointer;
    }

    .sidebar-shop-container-filter {
        display: none;
    }

    .sidebar-shop-container-display-promotion {
        display: none;
    }

    .closed-menu-burger-shop {
        position: absolute;
        top: 8px;
        right:20px;
        font-size: 1.5em;
        color: #607576;
        cursor: pointer;
    }
}

@media screen and (min-width: 290px) and (max-width: 400px) {
    .sidebar-shop {
        position: absolute;
        top: 80px;
        right: 0;
        width: 90%;
        z-index: 1000;
    }

    .sidebar-shop-container-filter {
        display: none;
    }

    .sidebar-shop-container-display-promotion {
        display: none;
    }

    .closed-menu-burger-shop {
        position: absolute;
        top: 10px;
        right:20px;
        font-size: 1.3em;
        color: #607576;
        cursor: pointer;
    }
}

@media screen and (min-width: 0px) and (max-width: 290px) {
    .sidebar-shop {
        position: absolute;
        top: 55px;
        right: 0;
        width: 90%;
        z-index: 1000;
    }

    .sidebar-shop-container-filter {
        display: none;
    }

    .sidebar-shop-container-display-promotion {
        display: none;
    }

    .closed-menu-burger-shop {
        position: absolute;
        top: 12px;
        right:20px;
        font-size: 1.2em;
        color: #607576;
        cursor: pointer;
    }
}
