.total-shop-product {
    text-align: center;
}

.title-shop-product {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-bottom: -15px;
}

.slogan-shop-product {
    color: #607576;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.subcategory-shop-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 0 10px;
    margin: 0 auto;
    max-width: 1050px;
}

.subcategory-chevron {
    font-size: 22px;
    cursor: pointer;
    user-select: none;
}

.subcategory-shop {
    overflow-x: hidden;
    border-radius: 150px 150px 150px 150px; /* Arrondit uniquement les côtés gauche et droit */
}

.subcategory-shop::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.subcategory {
    background-color: #EEEEEE;
    padding: 10px;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 200px;
    text-align: center;
    margin-right: 30px;
}

.subcategory:hover {
    background-color: #FDB819;
}

.subcategory-row {
    display: flex;
    flex-shrink: 0;
}

.subcategory a {
    color: #000; /* Définissez la couleur que vous souhaitez pour les liens non visités */
    text-decoration: none; /* Retire le soulignement */
}

.subcategory a:visited {
    color: #000; /* Assure que la couleur reste la même pour les liens visités */
    text-decoration: none;
}


.title-subcategory-shop {
    font-size: 12px;
    margin: 0;
    white-space: nowrap;
}

@media screen and (min-width: 950px) and (max-width: 1280px) {
    .subcategory-shop-wrapper {
        max-width: 850px;
    }
}

@media screen and (min-width: 650px) and (max-width: 950px) {
    .subcategory-shop-wrapper {
        max-width: 650px;
    }
}

@media screen and (min-width: 330px) and (max-width: 650px) {
    .subcategory-shop-wrapper {
        max-width: 450px;
    }

    .subcategory {
        background-color: #EEEEEE;
        padding: 8px;
    }

    .title-subcategory-shop {
        font-size: 11px;
    }

    .subcategory-chevron {
        font-size: 18px;
    }
}

@media screen and (min-width: 500px) and (max-width: 580px) {
    .title-shop-product {
        font-size: 25px;
    }

    .slogan-shop-product {
        font-size: 14px;
    }
}

@media screen and (min-width: 440px) and (max-width: 500px) {
    .title-shop-product {
        font-size: 25px;
    }

    .slogan-shop-product {
        font-size: 14px;
    }
}

@media screen and (min-width: 390px) and (max-width: 440px) {
    .title-shop-product {
        font-size: 22px;
    }

    .slogan-shop-product {
        font-size: 12px;
    }
}

@media screen and (min-width: 350px) and (max-width: 390px) {
    .title-shop-product {
        font-size: 20px;
        margin-bottom: -10px;
    }

    .slogan-shop-product {
        font-size: 11px;
    }
}

@media screen and (min-width: 250px) and (max-width: 330px) {
    .subcategory-shop-wrapper {
        max-width: 350px;
    }

    .subcategory {
        background-color: #EEEEEE;
        padding: 7px;
        min-width: 180px;
    }

    .title-subcategory-shop {
        font-size: 10px;
    }

    .subcategory-chevron {
        font-size: 16px;
    }
}

@media screen and (min-width: 330px) and (max-width: 350px) {
    .title-shop-product {
        font-size: 18px;
        margin-bottom: -10px;
    }

    .slogan-shop-product {
        font-size: 10px;
    }
}

@media screen and (min-width: 280px) and (max-width: 330px) {
    .title-shop-product {
        font-size: 16px;
        margin-bottom: -10px;
    }

    .slogan-shop-product {
        font-size: 8px;
    }
}

@media screen and (min-width: 230px) and (max-width: 250px) {
    .subcategory-shop-wrapper {
        max-width: 350px;
    }

    .subcategory {
        background-color: #EEEEEE;
        padding: 6px;
        min-width: 160px;
    }

    .title-subcategory-shop {
        font-size: 8px;
    }

    .subcategory-chevron {
        font-size: 14px;
    }
}

@media screen and (min-width: 250px) and (max-width: 280px) {
    .title-shop-product {
        font-size: 14px;
        margin-bottom: -5px;
    }

    .slogan-shop-product {
        font-size: 7px;
    }
}

@media screen and (min-width: 190px) and (max-width: 250px) {
    .title-shop-product {
        font-size: 13px;
        margin-bottom: -5px;
    }

    .slogan-shop-product {
        font-size: 7px;
    }
}

@media screen and (min-width: 0px) and (max-width: 230px) {
    .subcategory-shop-wrapper {
        max-width: 200px;
    }

    .subcategory {
        background-color: #EEEEEE;
        padding: 6px;
        min-width: 160px;
    }

    .title-subcategory-shop {
        font-size: 8px;
    }

    .subcategory-chevron {
        font-size: 14px;
    }
}

@media screen and (min-width: 0px) and (max-width: 190px) {
    .title-shop-product {
        font-size: 11px;
        margin-bottom: -5px;
    }

    .slogan-shop-product {
        font-size: 6px;
    }
}