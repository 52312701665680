.payment-title {
    margin-top: 180px;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.payment-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.total-amount {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #555;
}

.express-checkout-element {
    margin-bottom: 20px;
}

.payment-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment-element {
    width: 100%;
    margin-bottom: 20px;
}

.payment-submit-button {
    background-color:  #fdb819;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.payment-submit-button:hover {
    background-color:  #ddd;
}

.error-message {
    color: #e74c3c;
    font-size: 0.9em;
    margin-top: 10px;
    text-align: center;
}

/* Page paiment réussi*/

.title-payment-succes {
    margin-top: 180px;
}
