.article-content {
    flex-grow: 1;
    margin-right: 80px;
    max-width: 800px;
    margin-top: 30px;
}

.article-content h1 {
    font-size: 24px;
    color: #333;
}

.article-content .article-meta {
    display: flex;
    align-items: center;
    color: #666;
}

.article-content .article-meta i {
    margin-right: 5px;
}

.article-body {
    max-width: 800px;
}

.article-body img {
    width: 100%;
    height: auto;
    margin: 20px 0;
}

.article-body h2 {
    font-size: 20px;
    margin-top: 20px;
    color: #333;
}

.article-body p {
    text-align: justify;
    font-size: 16px;
    line-height: 1.5;
    color: #666;
}

.article-body a {
    color: white;
    font-weight: 700;
    text-decoration: none;
}

.blog-post-related-article {
    display: flex;
    align-items: center;
    list-style-type: none;
    /* Enlève les puces */
    background-color: #f5f5f5;
    /* Couleur de fond gris clair */
    border-left: 4px solid #FDB819;
    /* Bordure gauche avec une couleur */
    margin: 20px 0;
    /* Marge autour de la boîte */
    white-space: nowrap;
}

.blog-post-related-article-title {
    width: 100px;
    /* Largeur du titre */
    color: #333;
    /* Couleur du texte */
    margin-right: 100px;
    /* Marge à droite pour séparer le titre du texte */
    margin-left: 10px;
}

.blog-post-related-article a {
    margin-top: 5px;
    text-decoration: underline;
    /* Soulignement du lien */
    font-weight: bold;
    /* Mettre le texte en gras */
}

.blog-post-related-article a:hover {
    text-decoration: none;
    /* Pas de soulignement au survol */
}

.blog-post-related-products-list {
    margin-top: 20px;
    width: 100%;
    height: 310px;
    display: flex;
    justify-content: space-around;
    border: 2px solid #f5f5f5;
}

.blog-post-related-products-list img {
    width: auto;
    height: auto;
}

.blog-post-related-product-item {
    width: 100%;
    text-align: center;
}

.blog-post-related-product-title {
    font-size: 14px;
    color: #607576;
    text-overflow: ellipsis;
    overflow: hidden;

}

.blog-post-related-product-link {
    background-color: #fdb819;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
}

.blog-post-related-product-link:hover {
    background-color: #ddd;
}

@media screen and (min-width: 1180px) and (max-width: 1210px) {
.article-content {
    margin-right: 50px;
}
}

@media screen and (min-width: 0px) and (max-width: 1180px) {
    .article-content {
        margin-right: 00px;
        max-width: 1000px;
    }
    }