.hidden {
    display: none;
}

.total-filtered-product-home {
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 20px;
}

.filtered-product-home-display {
    flex-direction: column;
}

.title-filtered-home-display {
    display: flex;
    align-items: center;
}

.title-filtered-home-display2 {
    margin-left: 20px;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.img-filtered-product-home-display {
    width: 160px;
    height: 140px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.filtered-product-home-display-list {
    height: 400px; /* Hauteur de la liste */
    overflow: hidden; /* Cache les éléments qui débordent */
    position: relative;
}

.filtered-product-home-display-list::-webkit-scrollbar {
    display: none; /* Masque la barre de défilement sur Chrome et Safari */
}

.filtered-product-home-display-list {
    height: 400px; /* Hauteur de la liste */
    overflow: hidden; /* Cache les éléments qui débordent */
    position: relative;
}

.filtered-product-home-display-list::-webkit-scrollbar {
    display: none; /* Masque la barre de défilement sur Chrome et Safari */
}

.filtered-product-home-item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.img-container-filtered {
    position: relative;
    width: 100%;
    height: 100%;
}

.img-overlay-filtered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6); /* Set white background with opacity */
    opacity: 0; /* Start with hidden overlay */
    transition: opacity 0.3s;
    z-index: 1;
}

.icon-overlay-filtered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 2;
}

.icon-overlay-filtered .bi-envelope-plus {
    font-size: 18px;  /* Ajuste la taille si nécessaire */
    color: #fdb819;   /* Couleur de l'icône */
    background-color: #607576;  /* Fond de l'icône */
    border-radius: 50%;  /* Forme arrondie */
    padding: 10px;  /* Espacement intérieur */
    transition: background-color 0.3s, color 0.3s;
}

.icon-overlay-filtered .bi-envelope-plus:hover {
    color: #607576;  /* Couleur au survol */
    background-color: #fdb819;  /* Fond au survol */
}

.icon-overlay-filtered.single-icon {
    justify-content: center; /* Centre l'icône unique */
    align-items: center;
    width: 100%;  /* Pour s'assurer que l'icône prend toute la largeur */
    display: flex;
}

.icon-overlay-filtered .icon-link-filtered {
    color: #fdb819;
    background-color: #607576;
    border-radius: 50%;
    padding: 10px;
}

.icon-overlay-filtered .icon-link-filtered:hover {
    color: #607576;
    background-color: #fdb819;
    border-radius: 50%;
    padding: 10px;
}

.filtered-product-home-item:hover .img-overlay-filtered {
    opacity: 1;
}

.filtered-product-home-item:hover .icon-overlay-filtered {
    opacity: 1;
}

.filtered-product-home-info {
    margin-left: 20px;
    text-align: left;
}

.filtered-product-home-display-name,
.filtered-product-home-display-price {
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 5px 0;
    padding-left: 10px;
    padding-right: 10px;
}

.filtered-product-home-weight {
    margin-bottom: -5px;
    font-size: 12px;
    color: #607576;
    padding-left: 10px;
    padding-right: 10px;
}

.original-price-filtered-product {
    text-decoration: line-through;
    color: #607576;
    font-size: 15px;
    margin-right: 10px;
}

.discounted-price-filtered-product {
    color: #fdb819;
    font-weight: bold;
    font-size: 14px;
}

.filtered-product-home-display-name {
    font-size: 13px;
    font-weight: 700;
}

.filtered-product-home-display-price {
    font-size: 14px;
    font-weight: 600;
    color: #fdb819;
}

@media screen and (min-width: 910px) and (max-width: 1170px) {
    .total-filtered-product-home {
        gap: 10px;
    }

    .title-filtered-home-display2 {
        font-size: 20px;
    }

    .chevron-filtered-home-display1 {
        font-size: 16px;
        transform: translateX(20%);
    }

    .chevron-filtered-home-display2 {
        font-size: 16px;
        transform: translateX(105%);
    }

    .chevron-filtered-home-display3 {
        font-size: 16px;
        transform: translateX(110%);
    }

    .img-filtered-product-home-display {
        width: 120px;
        height: 100px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 120px;
    }

    .filtered-product-home-display-name {
        font-size: 11px;
    }

    .filtered-product-home-display-price {
        font-size: 12px;
    }
}

@media screen and (min-width: 830px) and (max-width: 910px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .title-filtered-home-display2 {
        font-size: 22px;
        margin-right: 30px;
    }

    .chevron-filtered-home-display1 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 230px;
        height: 200px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 250px;
    }
}

@media screen and (min-width: 775px) and (max-width: 830px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .title-filtered-home-display2 {
        font-size: 22px;
        margin-right: 30px;
    }

    .chevron-filtered-home-display1 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 230px;
        height: 200px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 250px;
    }
}

@media screen and (min-width: 670px) and (max-width: 775px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .title-filtered-home-display2 {
        font-size: 22px;
        margin-right: 30px;
    }

    .chevron-filtered-home-display1 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 20px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 230px;
        height: 200px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 250px;
    }
}

@media screen and (min-width: 550px) and (max-width: 670px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .title-filtered-home-display2 {
        font-size: 20px;
        margin-right: 30px;
    }

    .chevron-filtered-home-display1 {
        font-size: 18px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 18px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 18px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 200px;
        height: 180px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 200px;
    }
}

@media screen and (min-width: 430px) and (max-width: 550px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .title-filtered-home-display2 {
        font-size: 18px;
        margin-right: 30px;
    }

    .chevron-filtered-home-display1 {
        font-size: 16px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 16px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 16px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 150px;
        height: 130px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 150px;
    }
}

@media screen and (min-width: 320px) and (max-width: 430px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .title-filtered-home-display2 {
        font-size: 16px;
        margin-right: 30px;
    }

    .chevron-filtered-home-display1 {
        font-size: 14px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 14px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 14px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 120px;
        height: 100px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 120px;
    }
}

@media screen and (min-width: 240px) and (max-width: 320px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .title-filtered-home-display2 {
        font-size: 14px;
        margin-right: 30px;
    }

    .chevron-filtered-home-display1 {
        font-size: 12px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 12px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 12px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 90px;
        height: 70px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 90px;
    }

    .filtered-product-home-display-name {
        font-size: 12px;
    }

    .filtered-product-home-display-price {
        font-size: 11px;
    }
}

@media screen and (min-width: 200px) and (max-width: 240px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .filtered-product-home-display1,
    .filtered-product-home-display2,
    .filtered-product-home-display3 {
        justify-content: center;
    }

    .title-filtered-home-display2 {
        font-size: 12px;
        margin-right: 20px;
    }

    .chevron-filtered-home-display1 {
        font-size: 10px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 10px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 10px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 70px;
        height: 50px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 70px;
    }

    .filtered-product-home-display-name {
        font-size: 10px;
    }

    .filtered-product-home-display-price {
        font-size: 9px;
    }
}

@media screen and (min-width: 0px) and (max-width: 200px) {
    .total-filtered-product-home {
        flex-direction: column;
        align-items: center;
        gap: 0px;
    }

    .filtered-product-home-item {
        justify-content: center;
    }

    .title-filtered-home-display2 {
        font-size: 10px;
        margin-right: 0px;
    }

    .chevron-filtered-home-display1 {
        font-size: 9px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display2 {
        font-size: 9px;
        transform: translateX(0%);
    }

    .chevron-filtered-home-display3 {
        font-size: 9px;
        transform: translateX(0%);
    }

    .img-filtered-product-home-display {
        width: 40px;
        height: 40px;
    }

    .filtered-product-home-display-name,
    .filtered-product-home-display-price {
        width: 40px;
    }

    .filtered-product-home-display-name {
        font-size: 9px;
    }

    .filtered-product-home-display-price {
        font-size: 8px;
    }
}