/* General filtered handicap */
/* ________________________________________________________________________ */
.filters-outer-wrapper {
    overflow: hidden;
    justify-content: center;
    margin-top: 20px;
}

.filters-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 1050px;
    margin: 0 auto;
}

.filters {
    display: flex;
    overflow-x: hidden; /* Cache le débordement horizontal */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    position: relative;
    border-radius: 150px 150px 150px 150px; /* Arrondit uniquement les côtés gauche et droit */
    margin-bottom: 20px;
}

#filter-container {
    display: flex;
    flex-shrink: 0;
}
.filters::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.filter-row {
    gap: 80px;
}

.filter-display {
    text-align: center;
}

.filter-display a {
    text-transform: uppercase;
    white-space: nowrap;
    text-decoration: none; /* Retirer le soulignement */
    color: #000000; /* Couleur du texte pour les liens normaux */
}

.filter-display a:visited {
    color: #000000; /* Couleur pour les liens visités (ici, noir pour qu'il soit identique aux liens normaux) */
}

.title-filtered {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.title-filtered:hover {
    color: #FDB819;
}

.img-filtered-handicap {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    cursor: pointer;
}

/* Responsive filtered handicap */
/* ________________________________________________________________________ */

@media screen and (min-width: 1000px) and (max-width: 1160px) {
    .filters-wrapper {
        width: 900px;
    }

    .img-filtered-handicap {
        width: 150px;
        height: 150px;
    }
    .title-filtered {
        font-size: 13px;
    }
}

@media screen and (min-width: 880px) and (max-width: 1000px) {
    .filters-wrapper {
        width: 800px;
    }

    .filter-row {
        gap: 60px;
    }

    .img-filtered-handicap {
        width: 130px;
        height: 130px;
    }
    .title-filtered {
        font-size: 12px;
    }
}

@media screen and (min-width: 780px) and (max-width: 880px) {
    .filters-wrapper {
        width: 700px;
    }

    .filter-row {
        gap: 50px;
    }

    .img-filtered-handicap {
        width: 130px;
        height: 130px;
    }
    .title-filtered {
        font-size: 12px;
    }
}

@media screen and (min-width: 680px) and (max-width: 780px) {
    .filters-wrapper {
        width: 600px;
    }

    
    .filter-row {
        gap: 30px;
    }

    .img-filtered-handicap {
        width: 120px;
        height: 120px;
    }
    .title-filtered {
        font-size: 12px;
    }
}

@media screen and (min-width: 580px) and (max-width: 680px) {
    .filters-wrapper {
        width: 500px;
    }
    
    .filter-row {
        gap: 30px;
    }

    .img-filtered-handicap {
        width: 120px;
        height: 120px;
    }
    .title-filtered {
        font-size: 12px;
    }
}

@media screen and (min-width: 480px) and (max-width: 580px) {
    .filters-wrapper {
        width: 400px;
    }
    
    .filter-row {
        gap: 20px;
    }

    .img-filtered-handicap {
        width: 100px;
        height: 100px;
    }
    .title-filtered {
        font-size: 11px;
    }
}

@media screen and (min-width: 380px) and (max-width: 480px) {
    .filters-wrapper {
        width: 300px;
    }
    
    .filter-row {
        gap: 20px;
    }

    .img-filtered-handicap {
        width: 100px;
        height: 100px;
    }
    .title-filtered {
        font-size: 11px;
    }
}

@media screen and (min-width: 330px) and (max-width: 380px) {
    .filters-wrapper {
        width: 250px;
    }
    
    .filter-row {
        gap: 15px;
    }

    .img-filtered-handicap {
        width: 100px;
        height: 100px;
    }
    .title-filtered {
        font-size: 11px;
    }
}

@media screen and (min-width: 250px) and (max-width: 330px) {
    .filters-wrapper {
        width: 200px;
    }
    
    .filter-row {
        gap: 15px;
    }

    .img-filtered-handicap {
        width: 80px;
        height: 80px;
    }
    .title-filtered {
        font-size: 10px;
    }
}

@media screen and (min-width: 230px) and (max-width: 250px) {
    .filters-wrapper {
        width: 170px;
    }
    
    .filter-row {
        gap: 10px;
    }

    .img-filtered-handicap {
        width: 70px;
        height: 70px;
    }
    .title-filtered {
        font-size: 9px;
    }
}

@media screen and (min-width: 0px) and (max-width: 230px) {
    .filters-wrapper {
        width: 150px;
    }
    
    .filter-row {
        gap: 10px;
    }

    .img-filtered-handicap {
        width: 60px;
        height: 60px;
    }
    .title-filtered {
        font-size: 8px;
    }
}