.form-connection-container {
    max-width: 400px;
    margin: auto;
    padding: 15px;
}

.title-connection-user {
    margin-top: 150px; /* Assurez-vous que la hauteur de la navbar est compensée */
    text-align: center;
}

.form-connection-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.form-connection-label-and-title {
    width: 100%;
}

.forgot-password {
    color: black;
    text-decoration: none;
}

.inscription {
    color: blue;
}

.form-connection-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.form-connection-label label {
    align-self: flex-start;
    margin-bottom: 5px;
    font-weight: 600;
}

.form-connection-label input,
.form-connection-label button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-connection-label button {
    font-size: 13px;
    font-weight: 500;
    background-color: #454545;
    color: white;
    border: none;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    margin-top: 15px;
}

.form-connection-label button:hover {
    background-color: #fdb819;
}

.already-connected-user {
    text-align: center;
}

.alert-danger {
    font-size: 18px;
    color: #721c24;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
}

/* style css reinitialisation mot de passe */

.reset-password-container {
    margin-top: 180px;
    text-align: center;
    justify-content: center;
}

/* Responsive adjustments */

@media (max-width: 866px) {
    .title-connection-user {
        margin-top: 120px; /* Ajustez la marge si nécessaire */
    }
}

@media (max-width: 768px) {
    .form-connection-container {
        max-width: 90%;
        padding: 10px;
    }

    .title-connection-user {
        font-size: 24px;
        margin-top: 130px; /* Ajustez la marge si nécessaire */
    }

    .form-connection-label input,
    .form-connection-label button {
        padding: 8px;
        font-size: 14px;
    }

    .alert-danger {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .title-connection-user {
        font-size: 20px;
        margin-top: 130px;
    }

    .form-connection-label input,
    .form-connection-label button {
        padding: 6px;
        font-size: 12px;
    }

    .alert-danger {
        font-size: 14px;
    }

    .reset-password-container {
        margin-top: 150px;
    }
}


@media (max-width: 330px) {
    .title-connection-user {
        margin-top: 120px;
    }
}

@media (max-width: 290px) {
    .title-connection-user {
        font-size: 18px;
        margin-top: 80px;
    }

    .form-connection-label input,
    .form-connection-label button {
        font-size: 10px;
    }

    .alert-danger {
        font-size: 12px;
    }

    .reset-password-container {
        margin-top: 100px;
    }
}