.container-personal-information {
    margin-top: 180px;
    text-align: left;
    width: 90%;
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
}

.title-personal-information {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 40px;
    text-align: center;
}

.tab-personal-information {
    overflow: hidden;
    border-bottom: 1px solid #ccc;
    background-color: #f1f1f1;
    display: flex;
    flex-wrap: wrap; /* Permet de gérer les petits écrans */
    justify-content: center;
}

.tab-personal-information button {
    background-color: inherit;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
    font-weight: 600;
    flex: 1 1 auto; /* Permet aux boutons de s'adapter à l'espace */
    text-align: center;
}

.tab-personal-information button:hover {
    background-color: #ddd;
}

.tab-personal-information button.active {
    background-color: #ccc;
}

.tabcontent-personal-information {
    display: none;
    padding: 12px;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    border-radius: 5px;
}

.tabcontent-personal-information p {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
}

/* Formulaire */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
}

.btn {
    background-color: #454545;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    border-radius: 4px;
    text-align: center;
}

.btn:hover {
    background-color: #fdb819;
}

/* Responsive adjustments */

@media (max-width: 866px) {
    .container-personal-information {
        margin-top: 150px;
    }
}

/* Pour les écrans moyens (tablettes) */
@media (max-width: 768px) {
    .container-personal-information {
        width: 95%;
    }

    .title-personal-information {
        font-size: 1.8rem;
        margin-bottom: 30px;
    }

    .tab-personal-information {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5px;
    }

    .tab-personal-information button {
        padding: 12px;
        font-size: 15px;
        flex: 1 1 45%; /* Réduction de la largeur sur les petits écrans */
    }

    .tabcontent-personal-information {
        padding: 10px;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        padding: 8px;
        font-size: 14px;
    }

    .btn {
        padding: 8px 15px;
        font-size: 14px;
    }
}

/* Pour les petits écrans (smartphones) */
@media (max-width: 480px) {
    .title-personal-information {
        font-size: 1.5rem;
    }

    .tab-personal-information {
        flex-direction: column; /* Les boutons passent en colonne */
    }

    .tab-personal-information button {
        padding: 10px;
        font-size: 14px;
        flex: 1 1 100%; /* Les boutons prennent toute la largeur */
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        font-size: 13px;
        padding: 6px;
    }

    .btn {
        padding: 7px 10px;
        font-size: 13px;
    }
}

@media (max-width: 400px) {
    .title-personal-information {
        font-size: 18px;
    }
    .container-personal-information {
        margin-top: 130px;
    }
}

/* Pour les très petits écrans */
@media (max-width: 290px) {
    .container-personal-information {
        margin-top: 110px;
    }

    .title-personal-information {
        font-size: 1.2rem;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        font-size: 12px;
        padding: 5px;
    }

    .btn {
        padding: 5px 8px;
        font-size: 12px;
    }
}
