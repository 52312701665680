.form-group-registration {
    display: flex;
    flex-direction: column;
    transition: opacity 3s ease, visibility 0.3s ease, height 0.5s ease, margin-top 0.5s ease;
}

.hidden-registration {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    margin-top: 0;
}

.visible-registration {
    opacity: 1;
    visibility: visible;
    height: auto;
    margin-top: 15px;
}

.form-registration-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
}

.form-registration h1 {
    margin-top: 150px; /* Assurez-vous que la hauteur de la navbar est compensée */
    text-align: center;
    margin-bottom: 30px;
}

.form-label-registration {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 600;
}

.form-control-registration {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.redirect-connect {
    text-decoration: none;
    color: blue;
}

.btn-registration {
    width: 100%;
    background-color: #454545;
    color: white;
    border: none;
    font-weight: 600;
    padding: 10px 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    text-align: center;
    margin-top: 15px;
}

.btn-registration:hover {
    background-color: #fdb819;
    color: white;
}

/* Responsive adjustments */

@media (max-width: 866px) {
    .form-registration h1 {
        margin-top: 120px; /* Ajustez la marge si nécessaire */
}
}

@media (max-width: 768px) {
    .form-registration-container {
        padding: 15px;
        max-width: 90%;
    }

    .form-registration h1 {
        font-size: 24px;
        margin-top: 130px; /* Ajustez la marge si nécessaire */
    }

    .form-control-registration {
        padding: 8px;
    }

    .btn-registration {
        padding: 8px 15px;
    }
}

@media (max-width: 480px) {
    .form-registration h1 {
        font-size: 20px;
        margin-top: 120px;
    }

    .form-control-registration {
        padding: 6px;
        font-size: 14px;
    }

    .btn-registration {
        font-size: 14px;
        padding: 6px 10px;
    }
}

@media (max-width: 290px) {
    .form-registration h1 {
        font-size: 18px;
        margin-top: 80px;
    }
}
